import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import { styles } from './styles';
import Login from '../pages/login';
import { routes } from '../routes';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../redux/actions/actionType';
import { request, apis } from '../httpUtil';
import { isLive, SAVE_STATE } from '../Utility/const';
import Loader from '../components/Loader';
import util from '../util';
import ForgotPassword from '../pages/forgotPassword/index';
import ResetPassword from '../pages/resetPassword';
import companyIconImg from '../img/s4b-logo.png';
import userProfile from '../img/user-profile.jpg';
import swal from 'sweetalert';
import { history } from '../redux/store/';
import Notification from '../components/Notification';
import Message from '../components/Message/Message';
import UserProfile from '../components/UserProfile';
import { Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener, Button } from '@material-ui/core';
import actionTypes from '../redux/actions/actionType';
import SendMessage from '../components/Message/SendMessage';
import axios from 'axios';
import VideoApp from '../components/VideoCall/App';
import TermPolicy from '../components/TermPolicy';

const classNames = require('classnames');
const PrivateRoute = ({ component: Component, authenticated, path, exact }) => {
    return <Route path={path} exact={exact} render={(props) => {
        return (<>
            {authenticated
                && <Component {...props} />
            }
        </>)
    }} />
}

let dispatch = null, appTitle, userData, saveStatus, activeTab;

const handleLogout = () => {
    swal({
        title: "Are you sure you want to log out?",
        icon: "info",
        buttons: true,
        dangerMode: true,
    }).then(async (isConfirm) => {
        if (isConfirm) {
            let params = {
                method: 'POST',
                withCredentials: true
            };
            const response = await request(apis.logout, params);
            if (response && response === "OK") {
                history.push('/');
                dispatch({ type: actions.RESET_STATE, activeTab: 1 });
            } else {
                util.listErrorAlert(response.error);
            }
        }
    });
}

const Drawer = (props) => {
    dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [messageAnchorEl, setMessageAnchorEl] = React.useState(null);
    const { classes } = props;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    userData = useSelector(state => state.appReducer.userData);
    appTitle = useSelector(state => state.appReducer.appTitleName);
    saveStatus = useSelector(state => state.appReducer.saveStatus);
    const showLoader = useSelector(state => state.appReducer.showLoader);
    const unReadMessageCount = useSelector(state => state.appReducer.unreadCount) || 0;
    const notifications = useSelector(state => state.appReducer.notifications) || [];

    activeTab = useSelector(state => state.appReducer.activeTab);

    const onChangeTab = (tab, route) => {
        dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: tab });
        // setActiveTab(tab)
        if (tab == 3) {
            dispatch({ type: actionTypes.SET_ACTIVE_CASEID, activeCaseId: 0 });
            dispatch({ type: actions.CLEAR_PROFILE_DATA });
        }
        //if tab is not caseload screen
        if (tab != 2) {
            dispatch({ type: actions.RESET_CASE });
        }
        history.push(route)
    }

    const getRecentConversation = async () => {
        await axios({
            method: 'POST', url: `${util.videoCallAPIEndPoints.getRecentConversation}`,
            data: {
                userId: userData.id,
                requestFrom: 'web'
            }
        }).then(data => {
            let recentConversation = data.data && data.data.conversation || [];
            recentConversation.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0));
            dispatch({ type: actions.SET_RECENT_CONVERSATION, recentConversation: recentConversation });
        })
    }

    const getUnReadCount = () => {
        if (userData && userData.id) {
            axios({
                method: 'POST',
                url: util.videoCallAPIEndPoints.unreadMsgsAndMissedCallCount,
                data: {
                    userId: userData.id,
                    requestFrom: "web"
                }
            }).then(e => {
                if (e.data) {
                    const totalUnreads = Number(e.data.totalUnreadMsgs) + Number(e.data.totalUnreadMissedVideoCalls);
                    dispatch({ type: actions.SET_UNREAD_COUNT, unreadCount: (totalUnreads || 0) });
                    if (window.location.hostname !== "localhost") {
                        setTimeout(() => {
                            getUnReadCount();
                        }, 15000);
                    }
                }
            });
        }
    }

    useEffect(() => {
        getUnReadCount();
    }, [userData])

    useEffect(async () => {
        const { location } = window;
        dispatch({ type: actions.SET_SHOW_LOADER, showLoader: true });
        const response = await request(apis.check_login);
        dispatch({ type: actions.SET_SHOW_LOADER, showLoader: false });
        if (response && response.data) {
            util.setLoginData(response, dispatch);
            util.setRecentMessage(response, dispatch)
        } else {
            if (location.hash != '#/termpolicy') {
                history.push('/');
            }
        }
        if (location.hash == '#/cases') {
            dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: 2 });
        } else if (location.hash == '#/assessment/0') {
            dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: 3 });
        }
    }, [])

    const onMessageClick = async (event) => {
        setMessageAnchorEl(event.currentTarget)
        await getRecentConversation();
        getUnReadCount();
    }

    const onMessageClose = () => {
        setMessageAnchorEl(null);
    };

    const onSendMessageClose = async (event) => {
        dispatch({ type: actions.SET_CHAT_DATA, chatData: {} });
        setMessageAnchorEl(true);
        await getRecentConversation();
        getUnReadCount();
    };

    const openMessage = Boolean(messageAnchorEl);
    const messageId = openMessage ? 'simple-popover' : undefined;

    const [openProfileToggle, setOpenProfileToggle] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleToggle = () => {
        setOpenProfileToggle((prevOpen) => !prevOpen);
    };
    const [openProfile, setOpenProfile] = React.useState(false);
    const handleProfile = () => {
        setOpenProfile((prevOpen) => !prevOpen);
    }
    const handleCloseProfile = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        if (event.target.id == 'logout') {
            handleLogout();
        }
        if (event.target.id == 'profile') {
            handleProfile();
        }
        setOpenProfileToggle(false);
    };

    const onTutorialClick = () => {
        window.open('https://www.loom.com/share/fb0d3ffbc0c0457e8c35a0738fec4ee4?sharedAppSource=personal_library')
    }

    const chatData = useSelector(state => state.appReducer.chatData);
    const { sendMessageAnchorEl, openChat } = chatData;

    return (
        <div>
            <Loader isOpen={showLoader} />
            {(userData && userData.id) || isLive ?
                <>
                    <div>
                        <img src={companyIconImg} className="company-icon" />
                        <svg className="msg-icon" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.1 7.6H32.3V24.7H7.6V28.5C7.6 29.545 8.455 30.4 9.5 30.4H30.4L38 38V9.5C38 8.455 37.145 7.6 36.1 7.6ZM28.5 19V1.9C28.5 0.855 27.645 0 26.6 0H1.9C0.855 0 0 0.855 0 1.9V28.5L7.6 20.9H26.6C27.645 20.9 28.5 20.045 28.5 19Z" fill="white" />
                        </svg>
                        <span className="msg-count" onClick={onMessageClick}><div className="msg-count-text" >{unReadMessageCount}</div></span>
                        {openMessage &&
                            <Message openMessage={openMessage} messageId={messageId} messageAnchorEl={messageAnchorEl} onMessageClose={onMessageClose} />
                        }
                        <SendMessage openSendMessage={openChat} sendMessageId={chatData.userId ? 'simple-popover' : undefined} messageAnchorEl={sendMessageAnchorEl} onSendMessageClose={onSendMessageClose} />
                    </div>
                    <div>
                        <svg className="notification-icon" width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 42C19.3375 42 21.25 40.0615 21.25 37.6923H12.75C12.75 40.0615 14.6412 42 17 42ZM29.75 29.0769V18.3077C29.75 11.6954 26.265 6.16 20.1875 4.69538V3.23077C20.1875 1.44308 18.7638 0 17 0C15.2362 0 13.8125 1.44308 13.8125 3.23077V4.69538C7.71375 6.16 4.25 11.6738 4.25 18.3077V29.0769L0 33.3846V35.5385H34V33.3846L29.75 29.0769Z" fill="white" />
                        </svg>
                        <span className="noti-count" onClick={handleClick}><div className="msg-count-text">{notifications ? notifications.length : 0}</div></span>
                        <Notification open={open} id={id} anchorEl={anchorEl} handleClose={handleClose} />
                    </div>
                    <div>
                        <img src={userProfile} className="user-profile-icon" />
                        <Button
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            className="profile-icon-arrow"
                        >
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.41 -4.62904e-07L6 4.58L10.59 -6.16331e-08L12 1.41L6 7.41L-6.16331e-08 1.41L1.41 -4.62904e-07Z" fill="white" />
                            </svg>
                        </Button>
                        <Popper open={openProfileToggle} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleCloseProfile}>
                                            <MenuList autoFocusItem={open} id="menu-list-grow" >
                                                <MenuItem id="profile" onClick={handleCloseProfile}>Profile</MenuItem>
                                                <MenuItem id="profile" onClick={onTutorialClick}>Tutorials</MenuItem>
                                                <MenuItem id="logout" onClick={handleCloseProfile}>Logout</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <UserProfile open={openProfile} handleClose={handleProfile} />
                    </div>

                    <div className="v876_3014">
                        <div className="v876_3015"></div>
                        <div className="v876_3024" onClick={() => onChangeTab(1, '/dashboard')}>
                            <div className={`v876_3025 ${activeTab == 1 && 'header-active-tab'}`}>
                                <div className="header-text">
                                    <span className="pdr-7">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 13.3333H10.6667V0H0V13.3333ZM0 24H10.6667V16H0V24ZM13.3333 24H24V10.6667H13.3333V24ZM13.3333 0V8H24V0H13.3333Z" fill="#01579B" />
                                        </svg>
                                    </span>
                                    <span className="v876_3028"> Dashboard</span>
                                </div>
                            </div>
                        </div>
                        <div className={`v876_3029 ${activeTab == 2 && 'header-active-tab'}`} onClick={() => onChangeTab(2, '/cases')}>
                            <div className="header-text">
                                <span className="pdr-7">
                                    <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.0909 7.71429C21.2036 7.71429 22.8964 5.99143 22.8964 3.85714C22.8964 1.72286 21.2036 0 19.0909 0C16.9782 0 15.2727 1.72286 15.2727 3.85714C15.2727 5.99143 16.9782 7.71429 19.0909 7.71429ZM8.90909 7.71429C11.0218 7.71429 12.7145 5.99143 12.7145 3.85714C12.7145 1.72286 11.0218 0 8.90909 0C6.79636 0 5.09091 1.72286 5.09091 3.85714C5.09091 5.99143 6.79636 7.71429 8.90909 7.71429ZM8.90909 10.2857C5.94364 10.2857 0 11.79 0 14.7857V18H17.8182V14.7857C17.8182 11.79 11.8745 10.2857 8.90909 10.2857ZM19.0909 10.2857C18.7218 10.2857 18.3018 10.3114 17.8564 10.35C19.3327 11.43 20.3636 12.8829 20.3636 14.7857V18H28V14.7857C28 11.79 22.0564 10.2857 19.0909 10.2857Z" fill="#01579B" />
                                    </svg>
                                </span>
                                <span className="v876_3032">Caseload</span>
                            </div>
                        </div>
                        <div className={`v876_3033 ${activeTab == 3 && 'header-active-tab'}`} onClick={() => onChangeTab(3, '/assessment/0')}>
                            <div className="header-text">
                                <span className="pdr-7">
                                    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.4 2.75H13L10.4 0H2.6C1.17 0 0.013 1.2375 0.013 2.75L0 19.25C0 20.7625 1.17 22 2.6 22H23.4C24.83 22 26 20.7625 26 19.25V5.5C26 3.9875 24.83 2.75 23.4 2.75ZM16.9 6.875C18.33 6.875 19.5 8.1125 19.5 9.625C19.5 11.1375 18.33 12.375 16.9 12.375C15.47 12.375 14.3 11.1375 14.3 9.625C14.3 8.1125 15.47 6.875 16.9 6.875ZM22.1 17.875H11.7V16.5C11.7 14.6713 15.171 13.75 16.9 13.75C18.629 13.75 22.1 14.6713 22.1 16.5V17.875Z" fill="#01579B" />
                                    </svg>
                                </span>
                                <span className="v876_3036">Add New Case</span>
                            </div>
                        </div>
                        <div className={`v876_3037 ${activeTab == 2 ? 'header-tab-2' : activeTab == 3 ? 'header-tab-3' : ''}`} />
                    </div>
                </>
                : null
            }
            <Switch>
                {!isLive && <Route path="/" exact component={Login} />}
                <Route path="/forgotPassword" exact component={ForgotPassword} />
                <Route path="/resetPassword/:id" exact component={ResetPassword} />
                <Route path="/termpolicy" exact component={TermPolicy} />
                <div className={classNames(classes.content)}>
                    {/* <div className={classes.toolbar}></div> */}
                    {
                        routes.map((route) => {
                            const Component = route.component;
                            const authenticated = userData && userData.id;
                            return (<React.Fragment key={route.path}>
                                <PrivateRoute path={route.path} component={Component} exact={route.exact} authenticated={authenticated} />
                            </React.Fragment>
                            );
                        })
                    }
                </div>
            </Switch>
            <VideoApp />
        </div >
    );
}
export default withStyles(styles, { withTheme: true })(React.memo(Drawer));
