import actions from '../actions/actionType';
import util from '../../util';
import { enums } from '../../Utility/const';

const initialState = {
    dialogData: {
        open: false,
        content: null,
        title: null
    },
    appTitleName: 'SAFE4BOTH',
    appTopSearch: '',
    isNewRecord: false,
    taskList: { total: 0, records: [] },
    caseList: { total: 0, records: [] },
    appointmentList: { total: 0, records: [] },
    activeCaseId: 0,
    showLoader: false,
    taskChartData: {},
    appointmentChartData: {},
    chartData: {},
    saveSectionStatus: {},
    siblingData: [],
    activity: null,
    awardList: { total: 0, records: [] },
    supportDocList: [],
    recordDoc: [],
    changedGroups: [],
    gridPaging: {
        pageNo: 0,
        pageSize: 10,
        orderBy: 'createdAt',
        dir: 'desc',
        selectedRow: null,
        searchValue: ''
    },
    caseScroll: 0,
    activeTab: 1,
    notifications: [],
    message: [],
    chatData: {
        openChat: false,
        sendMessageAnchorEl: null
    },
    recentConversation: [],
    remoteUserData: {
        openVideoCall: false,
        id: '',
        name: ''
    },
    caseNote: [],
    otherSupportPersonData: [],
    otherSubstanceExpoData: [],
    reports:{ total: 0, records: []}

};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_SCROLL:
            return { ...state, ...{ caseScroll: action.caseScroll } };
        case actions.SET_CHART_FILTERS:
            return { ...state, ...{ chartFilter: action.chartFilter } };
        case actions.RESET_STATE:
            if (action.activeTab) {
                state = Object.assign({}, state, { activeTab: action.activeTab });
            }
            state = Object.assign({}, state, { userData: null })
            return {
                ...state, ...{
                    gridPaging: {
                        pageNo: 0,
                        pageSize: 10,
                        orderBy: 'createdAt',
                        dir: 'desc',
                        selectedRow: null,
                        searchValue: ''
                    }, appTopSearch: '', activeCaseId: 0
                }

            };
        case actions.SET_PAGING:
            return { ...state, ...{ gridPaging: action.gridPaging } };
        case actions.SET_USER_DATA:
            return { ...state, ...{ userData: action.userData } };
        case actions.SET_FIELDS:
            return { ...state, ...{ formFields: action.formFields } };
        case actions.SET_FORM_DATA:
            let { formData } = action;
            let { key, group, value } = formData;
            let newData = state[group];
            newData = Array.isArray(newData) ? [].concat(newData) : { ...newData };
            if (!newData) {
                newData = {};
            }
            if (key && !Array.isArray(newData)) { // save data as array
                newData = [];
            }
            value.changed = true;
            if (value && value.isMultiDropDown != undefined && value.isMultiDropDown != null) {
                let index = newData.findIndex(rec => rec[key] === value[key] && (rec.newId == value.newId || rec.id == value.newId));
                if (index > -1) {
                    newData[index] = Object.assign(newData[index], value);
                } else {
                    newData.push(value);
                }
            }
            else if (key) {
                let index = newData.findIndex(rec => rec[key] === value[key]);
                if (index > -1) {
                    if (newData[index] && !newData[index].updatedDate && value && value.signature) {
                        newData[index].updatedDate = Date.parse(new Date())
                    } else if (newData[index] && newData[index].updatedDate && value && value.signature == '') {
                        newData[index].updatedDate = 0;
                    }

                    newData[index] = Object.assign(newData[index], value)
                } else {
                    if (value && value.signature) {
                        value.updatedDate = Date.parse(new Date())
                    }
                    newData.push(Object.assign({}, value));
                }
            } else {
                newData = Object.assign(newData, value);
            }
            const changedGroups = [].concat(state.changedGroups);
            if (group == 'monitoring') {
                if (changedGroups.indexOf(group) < 0 && !(newData[0] && newData[0].defaultAddSection)) {
                    changedGroups.push(group);
                }
            }
            else if (changedGroups.indexOf(group) < 0) {
                changedGroups.push(group);
            }
            return {
                ...state, ...{ [group]: newData }, ...{ changedGroups: changedGroups }
            };
        case actions.SET_FORM_MONITORING_REMOVE:
            let { formDataRemove } = action;
            let { groupRemove, index } = formDataRemove;
            let newDataRemove = state[groupRemove];

            if (newDataRemove[index] && newDataRemove[index].id) {
                newDataRemove[index].isDeleted = 1;
                newDataRemove[index].changed = true;
            } else {
                newDataRemove.splice(index, 1);
            }

            const changedGroupsRemove = [].concat(state.changedGroups);
            if (changedGroupsRemove.indexOf(groupRemove) < 0) {
                changedGroupsRemove.push(groupRemove);
            }

            return {
                ...state, ...{ [groupRemove]: [...newDataRemove] }, ...{ changedGroups: changedGroupsRemove }
            };
        case actions.SET_DIALOG_DATA:
            return { ...state, ...{ dialogData: action.value } };
        case actions.SET_TITLE_NAME:
            return { ...state, ...{ appTitleName: action.appTitleName } };
        case actions.SET_TOP_SEARCH:
            return { ...state, ...{ appTopSearch: action.appTopSearch } };
        case actions.SET_ACTIVITY_DATA:
            return { ...state, ...{ taskData: action.data } };
        case actions.SET_APPOINTMENT_DATA:
            return { ...state, ...{ appointmentData: action.data } };
        case actions.LOAD_PROFILE_DATA:
            let { data } = action;
            const { formFields } = data;
            let newState = { ...state };
            const cData = data.updatedData.caseData, allContacts = data.updatedData.contacts, caseSubs = data.updatedData.caseSubstance, allNeeds = data.updatedData.assessmentNeed,
                allTags = data.updatedData.tags, caseMonitoring = data.updatedData.monitoring, caseConsent = data.updatedData.consent,
                cDetail = data.updatedData.caseDetail;

            if (cData) {
                newState = {
                    ...newState, ...{ caseData: cData }
                };
            }
            if (cDetail) {
                newState = { ...newState, ...{ caseDetail: cDetail } }
            }
            //  filter data according to contact type
            if (allContacts && allContacts.length > 0) {
                newState = { ...newState, ...{ motherContact: util.filterData(allContacts, formFields.infantContactFields.mother[0], 'contactTypeId') } };
                newState = { ...newState, ...{ fatherContact: util.filterData(allContacts, formFields.infantContactFields.father[0], 'contactTypeId') } };
                newState = { ...newState, ...{ careGiverContact: util.filterData(allContacts, formFields.infantContactFields.otherCaregiver[0], 'contactTypeId') } };
                newState = { ...newState, ...{ siblingData: util.filterData(allContacts, [{ typeId: enums.lookup.sibling }], 'contactTypeId') } };
                newState = { ...newState, ...{ otherSupportPersonData: util.filterData(allContacts, [{ typeId: enums.lookup.otherSupportPerson }], 'contactTypeId') } };
                newState = { ...newState, ...{ contacts: allContacts } };
            }
            if (caseSubs && caseSubs.length > 0) {
                newState = { ...newState, ...{ caseSubstance: caseSubs } };
                newState = { ...newState, ...{ otherSubstanceExpoData: util.filterData(caseSubs, [{ typeId: enums.lookup.otherSubstanceExpo }], 'substanceTypeId') } };
            }
            if (allNeeds) {
                newState = { ...newState, ...{ motherNeeds: util.filterData(allNeeds, formFields.motherNeedFields.assessmentNeed, 'needTypeId') } }
                newState = { ...newState, ...{ infantNeeds: util.filterData(allNeeds, formFields.infantNeedFields.assessmentNeed, 'needTypeId') } }
                newState = { ...newState, ...{ fatherNeeds: util.filterData(allNeeds, formFields.fatherNeedFields.assessmentNeed, 'needTypeId') } }
            }
            if (allTags) {
                newState = { ...newState, ...{ educationTags: util.filterData(allTags, formFields.healthEduFields.educationTags, 'tagId') } }
                newState = { ...newState, ...{ microEducationTags: util.filterData(allTags, formFields.healthEduFields.microEducationTags, 'tagId') } }
                newState = { ...newState, ...{ otherEducationtags: util.filterData(allTags, formFields.healthEduFields.otherEducationtags, 'tagId') } }
                newState = { ...newState, ...{ infantSafeTags: util.filterData(allTags, formFields.infantSafeFields.infantSafeTags, 'tagId') } }
                newState = { ...newState, ...{ childSafetyTags: util.filterData(allTags, formFields.childSafetyFields.childSafetyTags, 'tagId') } }
            }
            if (caseMonitoring) {
                newState = {
                    ...newState, ...{ monitoring: caseMonitoring }
                }
            }
            if (caseConsent) {
                newState = { ...newState, ...{ consent: caseConsent } }
            }
            return newState;
        case actions.SET_PROFILE_DATA:
            let { updatedData } = action;
            const
                { caseData,
                    motherContact,
                    fatherContact,
                    careGiverContact,
                    contacts,
                    caseSubstance,
                    infantNeeds,
                    motherNeeds,
                    fatherNeeds,
                    educationTags,
                    microEducationTags,
                    otherEducationtags,
                    infantSafeTags,
                    childSafetyTags,
                    monitoring,
                    consent, siblingData,
                    caseDetail,
                    otherSubstanceExpoData,
                    otherSupportPersonData } = updatedData
            let updatedState = { ...state };
            if (caseData) {
                updatedState = { ...updatedState, caseData }
            }
            if (motherContact && motherContact.length > 0) {
                updatedState = { ...updatedState, motherContact }
            }
            if (fatherContact && fatherContact.length > 0) {
                updatedState = { ...updatedState, fatherContact }
            }
            if (careGiverContact && careGiverContact.length > 0) {
                updatedState = { ...updatedState, careGiverContact }
            }
            if (siblingData && siblingData.length > 0) {
                updatedState = { ...updatedState, siblingData }
            }
            if (contacts && contacts.length > 0) {
                updatedState = { ...updatedState, contacts }
            }
            if (caseSubstance && caseSubstance.length > 0) {
                updatedState = { ...updatedState, caseSubstance }
            }
            if (otherSubstanceExpoData && otherSubstanceExpoData.length > 0) {
                updatedState = { ...updatedState, otherSubstanceExpoData }
            }
            if (motherNeeds && motherNeeds.length > 0) {
                updatedState = { ...updatedState, motherNeeds }
            }
            if (fatherNeeds && fatherNeeds.length > 0) {
                updatedState = { ...updatedState, fatherNeeds }
            }
            if (infantNeeds && infantNeeds.length > 0) {
                updatedState = { ...updatedState, infantNeeds }
            }
            if (educationTags && educationTags.length > 0) {
                updatedState = { ...updatedState, educationTags }
            }
            if (microEducationTags && microEducationTags.length > 0) {
                updatedState = { ...updatedState, microEducationTags }
            }
            if (otherEducationtags && otherEducationtags.length > 0) {
                updatedState = { ...updatedState, otherEducationtags }
            }
            if (infantSafeTags && infantSafeTags.length > 0) {
                updatedState = { ...updatedState, infantSafeTags }
            }
            if (childSafetyTags && childSafetyTags.length > 0) {
                updatedState = { ...updatedState, childSafetyTags }
            }

            if (monitoring && monitoring.length > 0) {
                updatedState = { ...updatedState, monitoring }
            }
            if (consent && consent.length > 0) {
                updatedState = { ...updatedState, consent }
            }
            if (caseDetail) {
                updatedState = { ...updatedState, caseDetail }
            }
            if (otherSupportPersonData && otherSupportPersonData.length > 0) {
                updatedState = { ...updatedState, otherSupportPersonData }
            }
            updatedState.changedGroups = [];
            return updatedState;
        case actions.SET_TASK_LIST:
            return { ...state, ...{ taskList: action.fetchRecord || action.taskList } };
        case actions.SET_CASE_LIST:
            return { ...state, ...{ caseList: action.fetchRecord } };
        case actions.SET_APPOINTMENT_LIST:
            return { ...state, ...{ appointmentList: action.fetchRecord } };
        case actions.SET_ACTIVE_CASEID:
            return { ...state, ...{ activeCaseId: action.activeCaseId } };
        case actions.SET_COMBO_DATA:
            return { ...state, ...{ comboData: action.comboData } };
        case actions.SET_SHOW_LOADER:
            return { ...state, ...{ showLoader: action.showLoader } };
        case actions.SET_CHART_DATA_TASKLIST:
            return { ...state, ...{ taskChartData: action.chartData } };
        case actions.SET_CHART_DATA_APPOINTMENTLIST:
            return { ...state, ...{ appointmentChartData: action.chartData } };
        case actions.CLEAR_PROFILE_DATA:
            return {
                ...state, ...{
                    caseData: null,
                    motherContact: [],
                    fatherContact: [],
                    careGiverContact: [],
                    contacts: [],
                    caseSubstance: [],
                    infantNeeds: [],
                    motherNeeds: [],
                    fatherNeeds: [],
                    educationTags: [],
                    microEducationTags: [],
                    otherEducationtags: [],
                    infantSafeTags: [],
                    childSafetyTags: [],
                    monitoring: [],
                    consent: [], siblingData: [],
                    caseDetail: null,
                    otherSubstanceExpoData: [],
                    otherSupportPersonData: [],
                    caseNote: []
                }
            };
        case actions.SET_NETWORK_STATE:
            return { ...state, ...{ network: action.network } };
        case actions.SET_SAVE_DATE_STATUS:
            return { ...state, ...{ saveSectionStatus: action.saveSectionStatus } };
        case actions.SET_STATUSLOG_LIST:
            return { ...state, ...{ statusLogList: action.fetchRecord } };
        case actions.SET_SUPPORTDOC_LIST:
            return { ...state, ...{ supportDocList: action.fetchRecord } };
        case actions.SET_SUPPORTPRO_LIST:
            return { ...state, ...{ supportProList: action.fetchRecord } };
        case actions.SET_SIBLING_DATA:
            let changeGroups = [].concat(state.changedGroups);
            if (changeGroups.indexOf("siblingData") < 0 && !(action.siblingData && action.siblingData[0] && action.siblingData[0].defaultAddSection)) {
                changeGroups.push("siblingData");
            }
            return { ...state, ...{ siblingData: action.siblingData }, ...{ changedGroups: changeGroups } };
        case actions.SET_ACTIVITY:
            return { ...state, ...{ activity: action.value } };
        case actions.SET_AWARD_LIST:
            return { ...state, ...{ awardList: action.fetchRecord } };
        case actions.SET_SUPPORT_DOCUMENTS:
            return { ...state, ...{ recordDoc: action.recordDoc } };
        case actions.SET_ACTIVE_TAB:
            return { ...state, ...{ activeTab: action.activeTab } };
        case actions.SET_NOTIFICATION_DATA:
            return { ...state, ...{ notifications: action.notifications } };
        case actions.SET_MESSAGE_DATA:
            return { ...state, ...{ message: action.message } };
        case actions.SET_CHAT_DATA:
            return { ...state, ...{ chatData: action.chatData } };
        case actions.SET_RECENT_CONVERSATION:
            return { ...state, ...{ recentConversation: action.recentConversation } };
        case actions.OPEN_VIDEO_CALL:
            return { ...state, ...{ remoteUserData: action.remoteUserData } };
        case actions.SET_UNREAD_COUNT:
            return { ...state, ...{ unreadCount: action.unreadCount } };
        case actions.RESET_CASE:
            return {
                ...state, ...{
                    gridPaging: {
                        pageNo: 0,
                        pageSize: 10,
                        orderBy: 'createdAt',
                        dir: 'desc',
                        selectedRow: null,
                        searchValue: ''
                    }
                }
            };
        case actions.SET_CASE_NOTE:
            return { ...state, ...{ caseNote: action.caseNote } };
        case actions.SET_SUPPORT_PERSON_DATA:
            let otherChangeGroups = [].concat(state.changedGroups);
            if (otherChangeGroups.indexOf("otherSupportPersonData") < 0 && !(action.otherSupportPersonData && action.otherSupportPersonData[0] && action.otherSupportPersonData[0].defaultAddSection)) {
                otherChangeGroups.push("otherSupportPersonData");
            }
            return { ...state, ...{ otherSupportPersonData: action.otherSupportPersonData }, ...{ changedGroups: otherChangeGroups } };
        case actions.SET_SUBSTANCE_OTHER_DATA:
            let otherSubstanceChange = [].concat(state.changedGroups);
            if (otherSubstanceChange.indexOf("otherSubstanceExpoData") < 0 && !(action.otherSubstanceExpoData && action.otherSubstanceExpoData[0] && action.otherSubstanceExpoData[0].defaultAddSection)) {
                otherSubstanceChange.push("otherSubstanceExpoData");
            }
            return { ...state, ...{ otherSubstanceExpoData: action.otherSubstanceExpoData }, ...{ changedGroups: otherSubstanceChange } };
        case actions.SET_REPORT_LIST:
            return { ...state, ...{ reports: action.fetchRecord } };
            default:
            return state;
    }
}

export default appReducer;


