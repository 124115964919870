import {
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  isSameDay,
  isWithinInterval,
  isSameMonth,
  addMonths,
  parse,
  isValid,
  min,
  max,
} from 'date-fns';

// eslint-disable-next-line no-unused-vars
import { DateRange } from './types';

export const identity = (x) => x;

export const chunks = (array, size) => (
  Array.from(
    { length: Math.ceil(array.length / size) },
    (_v, i) => array.slice(i * size, i * size + size),
  )
);

export const combine = (...args) => args.filter(identity).join(' ');

// Date
export const getDaysInMonth = (date) => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek);) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = (({ startDate }, day) => {
  return (startDate && isSameDay(day, startDate))
});

export const isEndOfRange = (({ endDate }, day) => {
  return (endDate && isSameDay(day, endDate))
});

export const inDateRange = (({ startDate, endDate }, day) => {
  return startDate && endDate &&
    (isWithinInterval(day, { start: startDate, end: endDate }) || isSameDay(day, startDate) || isSameDay(day, endDate))
})

export const isRangeSameDay = ({ startDate, endDate }) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

export const parseOptionalDate = (date, defaultValue) => {
  if (date) {
    const parsed = Date.parse(date);
    console.log('valid', isValid(parsed))
    if (isValid(parsed)) return parsed;
  }
  return defaultValue;
};

export const getValidatedMonths = (range, minDate, maxDate) => {
  const { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
  }
  return [startDate, endDate];
};
