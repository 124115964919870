import * as React from 'react';
import { enums, contactField } from '../Utility/const';
import familyInformation from '../Utility/contact.json'
import { store } from '../redux/store';
import swal from 'sweetalert';
import axios from 'axios';
const moment = require('moment');

const mapProps = (fields, props) => {
    let mappedFields = [];
    for (let i = 0, len = fields.length; i < len; i++) {
        mappedFields.push(Object.assign(fields[i], props));
    }
    return mappedFields;
}

//Add multiple rows according to data except default or first row that's came from lookup
const addOtherRows = (fieldId, compareFieldId, fieldInnerObj, innerObj, data, fields, obj) => {
    if (fieldId == compareFieldId && data && data.length > 0) {
        for (const e of data) {
            if (e.isMultiDropDown != 0 && e.id) {
                let newObj = { ...obj };
                newObj.newId = e.id;
                newObj.isMultiDropDown = e.isMultiDropDown ? 1 : 0;
                newObj.autoAddRow = true;
                fields[fieldInnerObj][innerObj].push(newObj);
            }
        }
        if (data.length > 0) {
            let newObj = { ...obj };
            newObj.newId = 0;
            newObj.isMultiDropDown = -1;
            newObj.autoAddRow = true;
            fields[fieldInnerObj][innerObj].push(newObj);
        }
    }
}

const onSubmit = '', dateFormat = 'MM/DD/Y', dateTimeAMPM = 'MM/DD/Y hh:mm A', dataTimewithSecond = 'MM/DD/YYYY HH:mm:ss', inputDateTimeFormat = 'YYYY-MM-DD[T]HH:mm:ss',
    hideWithdrawal = ['Heroin', 'Cocaine', 'Heroin/Fentanyl', 'PCP', 'Alcohol'], mobileAppBaseUrl = 'https://test.safe4both.bentenlab.com',
    needsCombos = [enums.lookup.infantNeedsOther, enums.lookup.motherSubstanceAbuse, enums.lookup.motherMentalHealth, enums.lookup.motherNeedsOther,
    enums.lookup.fatherNeedsSubstanceAbuse, enums.lookup.fatherNeedsOther, enums.lookup.fatherNeedsMentalHealth],
    videoCallAPIEndPoints = {
        getRecentConversation: mobileAppBaseUrl + '/videoCallActivity/getRecentConversationWithCallInfo',
        getChatAndCallHistory: mobileAppBaseUrl + '/videoCallActivity/getChatAndCallHistory',
        unreadMsgsAndMissedCallCount: mobileAppBaseUrl + '/videoCallActivity/unread-count',
        addCallActivity: mobileAppBaseUrl + '/videoCallActivity/addCallActivity'
    };
export default {
    version: '1.0.0',
    dateFormat: dateFormat,
    dateTimeAMPM: dateTimeAMPM,
    datePickerFormat: 'MM/dd/yyyy',
    dateTimePickerFormat: 'MM/dd/yyyy hh:mm a',
    dateTimePickerSSFormat: dataTimewithSecond,
    inputDateTimeFormat: inputDateTimeFormat,
    hideWithdrawal: hideWithdrawal,
    chartDateFormat: "MMM Do, YYYY",
    dateRangeFormat: "MMM DD YYYY",
    chatDateFormat: 'DD MMM, hh:mm a',
    onSubmit: onSubmit,
    datePickerFormatConfig: {
        'month': { format: 'MM', caption: 'Mon', step: 1, },
        'year': { format: 'YYYY', caption: 'Year', step: 1, },
        'date': { format: 'DD', caption: 'Day', step: 1, }
    },
    dateTimePickerFormatConfig: {
        'month': { format: 'MM', caption: 'Mon', step: 1, },
        'year': { format: 'YYYY', caption: 'Year', step: 1, },
        'date': { format: 'DD', caption: 'Day', step: 1, },
        'hour': { format: 'hh', caption: 'Hour', step: 1, },
        'minute': { format: 'mm', caption: 'Min', step: 1, },
        'second': { format: 'ss', caption: 'Sec', step: 1, }
    },
    monthNames: ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ],
    resetError: function (error) {
        if (error) {
            const index = error.findIndex(d => d.type === "email")
            index > -1 && error.splice(index, 1)
        }
    },
    validation: function (field = {}, value, formSubmit) {
        let { validations, customStringValue } = field;
        if (formSubmit && (value === undefined || value === null)) {
            value = '';
        }
        customStringValue = customStringValue && JSON.parse(customStringValue);
        validations = validations || (customStringValue && customStringValue.validations) || []
        let error = []
        if (validations && validations.length > 0) {
            validations && validations.forEach(d => {
                if (d === 'required') {
                    if (value === null || ((value !== undefined && value.toString() !== "Invalid Date") && value.length < 1) || (typeof value === 'number' && value === 0)) {
                        error.push({ type: "required", helperText: field.helperText ? field.helperText : ``, error: true });
                    } else {
                        this.resetError(error);
                    }
                }
                else if (d === 'email' || field.format === "email" && value) {
                    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (value && !regex.test(String(value).toLowerCase())) {
                        error.push({ type: "email", helperText: `Email is not valid`, error: true });
                    } else {
                        this.resetError(error);
                    }
                } else if (d === 'invalid' && field.type === "dateTime" && value) {
                    if (!moment(new Date(value), 'MM/DD/YYYY')._isValid) {
                        error.push({ type: "invalid", helperText: field.helperText ? field.helperText : `Invalid Date`, error: true });
                    }
                }
                else if (typeof d === 'object') {
                    if (field.type === "weight" && value) {
                        value = value && JSON.parse(value).lb;
                    }

                    if (d.type === 'length') {
                        let { min = 2, max = 20 } = d;
                        value = value && value.trim();
                        if (value && !(value.toString().length <= max && value.toString().length >= min)) {
                            error.push({ type: "length", helperText: field.helperText ? field.helperText : `${field.label || field.displayValue} limit min: ${min} and max: ${max}`, error: true });
                        } else {
                            this.resetError(error);
                        }
                    } else if (d.type === 'date') {
                        let { min, max } = d;

                        /// check mother and date valid
                        let now = moment();
                        let childDom = document.getElementById('infantBirthDate'), childDOB, fatherid = document.getElementById('fatherdateofbirth');
                        if (childDom && field.id === "motherdateofbirth") {
                            childDOB = childDom.value && moment(childDom.value.replace('_', ''));
                            if (childDOB && !(moment(value).diff(childDOB) < 0)) {
                                error.push({ type: "date", helperText: `Mother's DOB not greater than Infant's DOB`, error: true });
                            } else {
                                this.resetError(error);
                            }
                        }
                        if (childDom && field.id === "fatherdateofbirth" && fatherid) {
                            //console.log(fatherid);
                            childDOB = childDom.value && moment(childDom.value.replace('_', ''));
                            if (childDOB && !(moment(value).diff(childDOB) < 0) && fatherid.value) {
                                error.push({ type: "date", helperText: `Father's DOB not greater than Infant's DOB`, error: true });
                            } else {
                                this.resetError(error);
                            }

                        }
                        if (field.name === "birthdate") {
                            if (value && !(moment(value).diff(now) < 0)) {
                                error.push({ type: "date", helperText: `Date of birth cannot be in future`, error: true });
                            } else {
                                this.resetError(error);
                            }
                        }

                        if (typeof value === 'number' && value) {
                            value = moment(value)
                        }
                        if (typeof (value) === 'string' && value) {
                            value = new Date(value);
                        }
                        if (value && !moment(value, 'MM/DD/YYYY').isBetween(moment(min, 'MM/DD/YYYY'), moment(max, 'MM/DD/YYYY'))) {
                            error.push({ type: "date", helperText: `Between min: ${min} and max: ${max}`, error: true });
                        } else {
                            this.resetError(error);
                        }
                    }
                }
                if (field.format === "cellNumber" && value) {
                    value = value.replace(/-/g, '');
                    if (value.length > 10 || value.length < 10) {
                        error.push({ type: "cellNumber", helperText: (field.isPhoneNumber || field.label == "Phone") ? 'Invalid phone number.' : 'Invalid cell number.', error: true });
                    } else {
                        this.resetError(error);
                    }
                }
            })
        }
        return error;
    },
    getGroupedFields: (formFields) => {
        const storeData = store.getState().appReducer;
        const infrantSafeData = storeData && storeData.infantSafeTags;
        const consentData = storeData && storeData.consent || [];
        let familyInformationFields = contactField;
        // Update conditional fields
        const checkFields = ["infantBirthDate", "infantGender", "infantBirthHospitalId"];
        familyInformationFields.infant.forEach((item) => {
            item.validations = item.validations ? item.validations : [];
            if (checkFields.indexOf(item.name) > -1) {
                if (storeData.caseData && storeData.caseData.infantDischargeDate) {
                    if (item.validations.indexOf("required") < 0)
                        item.validations.push("required");
                }
                else if (item.validations.indexOf("required") > -1) {
                    item.validations.pop("required");
                }
            }
        });
        const infrantSignatureData = infrantSafeData && infrantSafeData.filter(e => e.tagId == enums.lookup.infrantSafetySignature && e.value);
        let fields = {
            subExpoFields: { caseSubstance: [] }, infantNeedFields: { assessmentNeed: [] }, motherNeedFields: { assessmentNeed: [] }, fatherNeedFields: { assessmentNeed: [] },
            healthEduFields: {
                educationTags: [],
                microEducationTags: [],
                otherEducationtags: [],
            },
            infantSafeFields: {
                infantSafeTags: []
            },
            childSafetyFields: {
                childSafetyTags: []
            },
            planOfSafeCareFields: {
                contacts: []
            },
            infantContactFields: familyInformationFields,
            monitoringFields: {
                monitoring: [
                ]
            },
            consentFields: {
                consent: []
            }
        }
        // filter form fields by category
        if (formFields) {
            for (let i = 0, len = formFields.length; i < len; i++) {
                let field = formFields[i];
                let fieldInfo = field.customStringValue ? JSON.parse(field.customStringValue) : {};
                switch (field.lookupTypeId) {
                    case enums.lookupType.substanceExposure:
                        if (field.id != 10) {
                            let substanceOpioidsData = storeData.caseSubstance && storeData.caseSubstance.filter(e => e.substanceTypeId == 3);
                            let substanceObj = Object.assign({
                                key: 'substanceTypeId',
                                group: 'caseSubstance',
                                typeId: field.id
                            }, field);

                            fields.subExpoFields.caseSubstance.push(substanceObj);
                            addOtherRows(field.id, 3, 'subExpoFields', 'caseSubstance', substanceOpioidsData, fields, substanceObj);
                        }
                        break;
                    case enums.lookupType.infantNeed:
                        let infantNeedOthers = storeData.infantNeeds && storeData.infantNeeds.filter(e => e.needTypeId == 14);
                        let infantNeedObj = Object.assign({
                            key: 'needTypeId',
                            typeId: field.id,
                            group: "infantNeeds",
                            validations: (fieldInfo.validations && fieldInfo.validations.length > 0) ? fieldInfo.validations : [],
                            fields: [{
                                name: "referredTo", validations: [{
                                    "type": "length",
                                    "min": 2,
                                    "max": 300
                                }]
                            },
                            {
                                "name": "cellNumber",
                                "type": "text",
                                "format": "cellNumber",
                                validations: [
                                    "cellNumber"
                                ]
                            },
                            {
                                name: "referralDate", validations: ["invalid", {
                                    "type": "date",
                                    "min": "01/01/1900",
                                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                                }]
                            },
                            {
                                name: "dayUpdate", validations: ["invalid", {
                                    "type": "date",
                                    "min": "01/01/1900",
                                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                                }]
                            }]
                        }, field);

                        fields.infantNeedFields.assessmentNeed.push(infantNeedObj);

                        addOtherRows(field.id, enums.lookup.infantNeedsOther, 'infantNeedFields', 'assessmentNeed', infantNeedOthers, fields, infantNeedObj);
                        break;
                    case enums.lookupType.motherNeed:
                        let substanceAbuseData = storeData.motherNeeds && storeData.motherNeeds.filter(e => e.needTypeId == enums.lookup.motherSubstanceAbuse);
                        let otherData = storeData.motherNeeds && storeData.motherNeeds.filter(e => e.needTypeId == enums.lookup.motherNeedsOther);
                        let mentalHealthData = storeData.motherNeeds && storeData.motherNeeds.filter(e => e.needTypeId == enums.lookup.motherMentalHealth);
                        let obj = Object.assign({
                            key: 'needTypeId',
                            typeId: field.id,
                            group: "motherNeeds",
                            validations: (fieldInfo.validations && fieldInfo.validations.length > 0) ? fieldInfo.validations : [],
                            fields: [{
                                name: "referredTo", validations: [{
                                    "type": "length",
                                    "min": 2,
                                    "max": 300
                                }]
                            },
                            {
                                "name": "cellNumber",
                                "type": "text",
                                "format": "cellNumber",
                                validations: [
                                    "cellNumber"
                                ]
                            },
                            {
                                name: "referralDate", validations: ["invalid", {
                                    "type": "date",
                                    "min": "01/01/1900",
                                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                                }]
                            }, {
                                name: "dayUpdate", validations: ["invalid", {
                                    "type": "date",
                                    "min": "01/01/1900",
                                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                                }]
                            }]
                        }, field);
                        fields.motherNeedFields.assessmentNeed.push(obj);

                        addOtherRows(field.id, enums.lookup.motherSubstanceAbuse, 'motherNeedFields', 'assessmentNeed', substanceAbuseData, fields, obj);
                        addOtherRows(field.id, enums.lookup.motherMentalHealth, 'motherNeedFields', 'assessmentNeed', mentalHealthData, fields, obj);
                        addOtherRows(field.id, enums.lookup.motherNeedsOther, 'motherNeedFields', 'assessmentNeed', otherData, fields, obj);
                        break;
                    case enums.lookupType.FatherNeed:
                        let fatherSubstanceAbuseData = storeData.fatherNeeds && storeData.fatherNeeds.filter(e => e.needTypeId == enums.lookup.fatherNeedsSubstanceAbuse);
                        let fatherOtherData = storeData.fatherNeeds && storeData.fatherNeeds.filter(e => e.needTypeId == enums.lookup.fatherNeedsOther);
                        let fatherMentalHealthData = storeData.fatherNeeds && storeData.fatherNeeds.filter(e => e.needTypeId == enums.lookup.fatherNeedsMentalHealth);
                        let fatherObj = Object.assign({
                            key: 'needTypeId',
                            typeId: field.id,
                            group: "fatherNeeds",
                            validations: (fieldInfo.validations && fieldInfo.validations.length > 0) ? fieldInfo.validations : [],
                            fields: [{
                                name: "referredTo", validations: [{
                                    "type": "length",
                                    "min": 2,
                                    "max": 300
                                }]
                            },
                            {
                                "name": "cellNumber",
                                "type": "text",
                                "format": "cellNumber",
                                validations: [
                                    "cellNumber"
                                ]
                            },
                            {
                                name: "referralDate", validations: ["invalid", {
                                    "type": "date",
                                    "min": "01/01/1900",
                                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                                }]
                            }, {
                                name: "dayUpdate", validations: ["invalid", {
                                    "type": "date",
                                    "min": "01/01/1900",
                                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                                }]
                            }]
                        }, field);

                        fields.fatherNeedFields.assessmentNeed.push(fatherObj)

                        addOtherRows(field.id, enums.lookup.fatherNeedsSubstanceAbuse, 'fatherNeedFields', 'assessmentNeed', fatherSubstanceAbuseData, fields, fatherObj);
                        addOtherRows(field.id, enums.lookup.fatherNeedsMentalHealth, 'fatherNeedFields', 'assessmentNeed', fatherMentalHealthData, fields, fatherObj);
                        addOtherRows(field.id, enums.lookup.fatherNeedsOther, 'fatherNeedFields', 'assessmentNeed', fatherOtherData, fields, fatherObj);
                        break;
                    case enums.lookupType.educationTags:
                        fields.healthEduFields.educationTags.push({
                            label: field.displayValue,
                            type: fieldInfo.type ? fieldInfo.type : "checkbox",
                            name: fieldInfo.name ? fieldInfo.name : "value",
                            layout: {
                                sm: 4,
                                md: 4,
                                lg: 3
                            },
                            key: 'tagId',
                            typeId: field.id,
                            id: fieldInfo.id ? fieldInfo.id : "",
                        });
                        break;
                    case enums.lookupType.MicrolearningEducationTags:
                        fields.healthEduFields.microEducationTags.push({
                            label: field.displayValue,
                            type: "checkbox",
                            name: "value",
                            layout: {
                                sm: 4,
                                md: 4,
                                lg: 3
                            },
                            key: 'tagId',
                            typeId: field.id
                        });
                        break;
                    case enums.lookupType.OtherEducationTags:
                        fields.healthEduFields.otherEducationtags.push({
                            label: field.displayValue,
                            type: "checkbox",
                            name: "value",
                            layout: {
                                sm: 4,
                                md: 4,
                                lg: 3
                            },
                            key: 'tagId',
                            typeId: field.id
                        });
                        break;
                    case enums.lookupType.infantSafeTags:
                        // CMS  #252 add name field after signature in section 6
                        if (infrantSignatureData && infrantSignatureData.length > 0 && field.id == enums.lookup.infrantSafetySignatureName) {
                            if (fieldInfo && fieldInfo.validations.indexOf("required") < 0) {
                                fieldInfo.validations.unshift("required");
                            }

                        } else if (fieldInfo && fieldInfo.validations && fieldInfo.validations.indexOf("required") > -1) {
                            fieldInfo.validations.pop("required");
                        }
                        fields.infantSafeFields.infantSafeTags.push({
                            label: field.displayValue,
                            type: fieldInfo.type ? fieldInfo.type : "checkbox",
                            name: fieldInfo.name ? fieldInfo.name : "value",
                            layout: fieldInfo.layout ? fieldInfo.layout : {
                                sm: 4,
                                md: 4,
                                lg: 3
                            },
                            key: 'tagId',
                            typeId: field.id,
                            id: fieldInfo.id ? fieldInfo.id : "",
                            validations: (fieldInfo && fieldInfo.validations && fieldInfo.validations.length > 0) ? fieldInfo.validations : []
                        });
                        break;
                    case enums.lookupType.childSafetyTags:
                        fields.childSafetyFields.childSafetyTags.push({
                            ...{
                                label: field.displayValue,
                                name: "value",
                                key: 'tagId',
                                typeId: field.id
                            }, ...fieldInfo
                        });

                        break;
                    case enums.lookupType.contactType:
                        if (fieldInfo.planOfSafeCare) {
                            fields.planOfSafeCareFields.contacts.push(
                                {
                                    label: field.displayValue,
                                    name: "fullName",
                                    type: "text",
                                    layout: fieldInfo.apptDate ? { xs: 3, sm: 3, md: 3, lg: 3 } : { sm: 6, md: 6, lg: 6 },
                                    key: 'contactTypeId',
                                    typeId: field.id,
                                    warning: (fieldInfo.warning) ? fieldInfo.warning : false,
                                    validations: [
                                        {
                                            "type": "length",
                                            "min": 2,
                                            "max": 50
                                        }
                                    ]
                                }
                            );
                            fields.planOfSafeCareFields.contacts.push({
                                "label": "Phone",
                                "name": "cellNumber",
                                "type": "text",
                                "format": "cellNumber",
                                layout: fieldInfo.apptDate ? { xs: 3, sm: 3, md: 3, lg: 3 } : { sm: 6, md: 6, lg: 6 },
                                key: 'contactTypeId',
                                typeId: field.id,
                                warning: (fieldInfo.warning) ? fieldInfo.warning : false,
                                validations: [
                                    "cellNumber"
                                ]

                            });
                            if (fieldInfo.apptDate) {
                                fields.planOfSafeCareFields.contacts.push({
                                    label: "Appt. Date",
                                    name: "appointmentDate",
                                    type: "dateTime",
                                    format: "MM/dd/yyyy",
                                    warning: (fieldInfo.warning) ? fieldInfo.warning : false,
                                    layout: { xs: 3, sm: 3, md: 3, lg: 3 },
                                    key: 'contactTypeId',
                                    validations: ["invalid", {
                                        "type": "date",
                                        "min": "01/01/1900",
                                        "max": moment().add(1, 'years').format('MM/DD/YYYY')
                                    }],
                                    typeId: field.id
                                });
                                fields.planOfSafeCareFields.contacts.push({
                                    label: "Frequency",
                                    name: "frequencyId",
                                    type: "dropdown",
                                    comboType: "Frequency",
                                    layout: { xs: 3, sm: 3, md: 3, lg: 3 },
                                    key: 'contactTypeId',
                                    typeId: field.id
                                });
                            }
                        } else {
                            let extraProps = {
                                key: 'contactTypeId',
                                typeId: field.id
                            };
                            if (field.displayValue === "Father") {
                                extraProps.group = "fatherContact";
                                fields.infantContactFields.father = mapProps(fields.infantContactFields.father, extraProps);
                            } else if (field.displayValue === "Mother") {
                                extraProps.group = "motherContact";
                                fields.infantContactFields.mother = mapProps(fields.infantContactFields.mother, extraProps);
                            } else if (field.displayValue === "Other Caregiver") {
                                extraProps.group = "otherCareContact";
                                fields.infantContactFields.otherCaregiver = mapProps(fields.infantContactFields.otherCaregiver, extraProps);
                            }
                        }
                        break;
                    case enums.lookupType.monitoringType:
                        fields.monitoringFields.monitoring.push({
                            label: field.displayValue,
                            name: fieldInfo.name,
                            type: fieldInfo.type ? fieldInfo.type : "text",
                            key: 'monitoringTypeId',
                            typeId: field.id
                        })
                        break;
                    case enums.lookupType.consentType:
                        let isSignature = consentData.filter(e => e.consentTypeId == field.id && e.signature) || [];
                        if (isSignature.length && fieldInfo.validations && fieldInfo.validations.indexOf("required") < 0 && !fieldInfo.disabled) {
                            fieldInfo.validations.push('required')
                        } else if (!isSignature.length && fieldInfo.validations && fieldInfo.validations.indexOf("required") > -1 && !fieldInfo.disabled) {
                            fieldInfo.validations.pop('required');
                        }
                        fields.consentFields.consent.push({
                            label: field.displayValue + " First Name",
                            name: "firstName",
                            type: fieldInfo.type ? fieldInfo.type : "text",
                            layout: { xs: 6, sm: 6, md: 3, lg: 3 },
                            key: 'consentTypeId',
                            typeId: field.id,
                            disabled: fieldInfo.disabled ? true : false,
                            validations: (fieldInfo.validations && fieldInfo.validations.length > 0) ? fieldInfo.validations : [],
                        },
                            {
                                label: field.displayValue + " Last Name",
                                name: "lastName",
                                type: fieldInfo.type ? fieldInfo.type : "text",
                                layout: { xs: 6, sm: 6, md: 3, lg: 3 },
                                key: 'consentTypeId',
                                typeId: field.id,
                                disabled: fieldInfo.disabled ? true : false,
                                validations: (fieldInfo.validations && fieldInfo.validations.length > 0) ? fieldInfo.validations : [],
                            },
                            {
                                name: "signature",
                                type: "signature",
                                layout: { xs: 6, sm: 6, md: 4, lg: 4 },
                                key: 'consentTypeId',
                                typeId: field.id
                            }, {
                            label: 'Date',
                            name: "updatedDate",
                            type: 'text',
                            layout: { xs: 6, sm: 6, md: 2, lg: 2 },
                            key: 'consentTypeId',
                            typeId: field.id,
                            disabled: true,
                            consentSignDate: true
                        });
                        break;
                    default:
                        break
                }
            }
        }
        return fields;
    },
    getStore: () => {
        return store.getState().appReducer;
    },
    getFormData: () => {
        let reduxStore = store.getState().appReducer;
        let changedData = {};
        const changedGroups = reduxStore.changedGroups;
        changedGroups && changedGroups.forEach((group) => {
            changedData[group] = reduxStore[group];
        });
        return changedData;
    },
    renderer: {
        combo: (field, record, comboType) => {
            const combos = store.getState().appReducer.comboData;
            const rowRecord = combos && combos[comboType] && combos[comboType].find(rec => rec.value === record[field]);
            // if (!rowRecord && field === 'activityPriorityId') {
            //     return <div className={"priority-col "}>  {'None'}</div>;
            // }
            if (!rowRecord) {
                return '';
            }
            if (field === 'activityPriorityId') {
                return <div className={"priority-col " + rowRecord.label.toLowerCase()}>  {rowRecord.label || ''}</div>
            } else {
                return <div>{rowRecord.label || ''}</div>
            }
        },
        dateTime: (field, record) => {
            return <div>{record[field] ? moment(record[field]).format(dateTimeAMPM) : ''}</div>
        },
        date: (field, record) => {
            return <div>{record[field] ? moment(record[field]).format(dateFormat) : ''}</div>
        },
        renderDelete: (field, record, comboType) => {
            const combos = store.getState().appReducer.comboData;
            const rowRecord = combos && combos[comboType] && combos[comboType].find(rec => rec.value === record[field]);
            return (rowRecord && rowRecord.label) || '';
        },
        statusFormatter: (field, record) => {
            return <div className="task-status-wrap"><div className={`task-status status-color-${record['activityStatusId']}`} />{record[field] || ''}</div>
        }
    },
    setChartData: (comboType, data, isTaks) => {
        const combos = store.getState().appReducer.comboData[comboType] || [],
            labels = [],
            labelsData = [];
        combos.forEach(combo => {
            let labelText = `${combo.label} ${isTaks && 'priority' || ''}`;
            labels.push(labelText);
            data[combo.value] ? labelsData.push(data[combo.value]) : labelsData.push(0);
        });
        return { labels, chartData: labelsData }
    },
    setBarChartData: (typeCombo, statusCombo, data, isAppointment) => {
        const barChartColors = ['#01579B', '#00C853', '#C4C4C4', '#ED1C24', '#7446B9']
        const typeComboData = store.getState().appReducer.comboData[typeCombo] || [];
        console.log('chartData ', store.getState().appReducer);
        const statusComboData = store.getState().appReducer.comboData[statusCombo] || [];
        console.log('statusComboData ', store.getState().appReducer);
        let labels = [], labelsData = [];
        typeComboData.forEach(combo => {
            labels.push(combo.label.split(' '));
        });
        statusComboData.forEach(combo => {
            const counts = [];
            const allCouonts = data[combo.value];
            if (isAppointment) {
                typeComboData.forEach(comboCount => {
                    counts.push(allCouonts && combo.value == comboCount.value ? allCouonts : 0);
                })
            } else {
                typeComboData.forEach(comboCount => {
                    counts.push(allCouonts && allCouonts[comboCount.value] ? allCouonts[comboCount.value] : 0);
                });
            }
            labelsData.push({
                label: combo.label,
                backgroundColor: barChartColors[labelsData.length],
                borderColor: barChartColors[labelsData.length],
                data: counts,
                barThickness: 14,
                borderJoinStyle: 'round'
            });
        });
        return {
            labels: labels,
            datasets: labelsData
        }
    },
    toDateTime: (timeStamp) => {
        return moment(timeStamp).format(dateTimeAMPM);
    },
    toDate: (timeStamp) => {
        return moment(timeStamp).format(dateFormat);
    },
    listErrorAlert: (error) => {
        error && swal({
            title: "Error",
            text: error,
            dangerMode: true,
            icon: 'error'
        })
    },
    numberField: (value) => {
        if (!value) return value;
        return typeof value === "number" ? value : value.replace(/[^\d]/g, '');
    },
    normalizeInput: (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || currentValue.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
            return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    },
    detailInfo: (record, info, renderer) => {
        let internalHtml = info.map(d => {
            let value = record[d.field] || '';
            if (value) {
                if (d.comboType) {
                    value = renderer.renderDelete(d.field, record, enums.lookupType[d.comboType])
                } else if (d.type === "date") {
                    value = moment(record[d.field]).format(dateTimeAMPM);
                }
            }
            return (`<div class="delete-col delete-col-size">${d.label}</div>
        <div class="delete-col">${value}</div>`)
        })
        return `<div class="delete-wrapper">${internalHtml.join('')}</div>`
    },
    filterData: (records, fields, key) => {
        if (!Array.isArray(fields)) {
            fields = [fields];
        }
        let toReturn = [];
        fields.forEach(field => {
            if (field.autoAddRow && field.isMultiDropDown) {
                return;
            }
            const rec = records.filter(r => r[key] === field.typeId);
            if (rec && rec.length > 0) {
                toReturn = toReturn.concat(rec);
            }
        });
        return toReturn;
    },
    preventData: function () {
        let me = this;
        return new Promise(async (resolve, reject) => {
            if (window.location.hash.indexOf('assessment') < 0) {
                resolve(true);
                return;
            }
            let reduxStore = store.getState().appReducer;
            const changedGroups = reduxStore.changedGroups;
            let isChange = false;
            changedGroups && changedGroups.forEach((group) => {
                let changeData = reduxStore[group];
                if (changeData && group !== 'taskData' && group !== 'appointmentData') {
                    if (!Array.isArray(changeData)) {
                        changeData = [changeData];
                    }
                    changeData.forEach((e) => {
                        if (e.changed) {
                            isChange = true;
                            return;
                        }
                    });
                }
            });
            if (isChange) {
                const isValid = me.checkValidation(true);
                if (isValid) {
                    me.onSubmit(true);
                    resolve(true);
                } else {
                    await swal({
                        title: "You have some unsaved data and some empty required fields. Do you want to save?",
                        icon: "warning",
                        buttons: ["Exit Without Saving", "Continue to enter required fields and save"]
                    }).then((willSave) => {
                        if (willSave) {
                            me.onSubmit(true)
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    });
                }
            } else {
                resolve(true);
            }
        })
    },
    isBase64: (str) => {
        if (typeof str === "boolean") {
            return false;
        }
        if (typeof str == 'string' && (str === '' || str.trim() === '')) { return false; }
        try {
            return str.includes("data:image/png;base64,");
        } catch (err) {
            return false;
        }
    },
    convertWeight: (value, type) => {
        let convertValue = ''
        if (+value) {
            if (type === 'lb') {
                convertValue = value / 16;
            } else {
                convertValue = value * 16;
            }
        }
        return convertValue;

    },
    getDefaultValueForConsent: (dataTypeId, field) => {
        let reduxStore = store.getState().appReducer;
        let value = '';
        let record = null;
        switch (dataTypeId) {
            case enums.tagType.infantDischargeDate:
                return reduxStore.caseData ? reduxStore.caseData.infantDischargeDate : ''
                break;
            case enums.consentType.mother:
                record = reduxStore.motherContact && reduxStore.motherContact[0];
                break;
            case enums.consentType.father:
                record = reduxStore.fatherContact && reduxStore.fatherContact[0];
                break;
            case enums.consentType.pOSCCoordinator:
                record = reduxStore.contacts && reduxStore.contacts.filter(rec => rec.contactTypeId === enums.contactType.pOSCCoordinator)
                if (record && record[0]) {
                    let fullName = record[0].fullName;
                    if (fullName) {
                        fullName = fullName.split(' ');
                        record = { firstName: fullName[0] };
                        if (fullName.length > 1) {
                            fullName.splice(0, 1);
                            record.lastName = fullName.join(' ');
                        }
                    }
                }
                break;
            case enums.consentType.otherCaregiver:
                record = reduxStore.careGiverContact && reduxStore.careGiverContact[0];
                break;
        }
        if (record) {
            value = record[field];
        }
        return value;
    },
    getDocData: (setcionFields, storedata) => {
        let newRecord = [];
        setcionFields.forEach((d, i) => {
            if (d.key === "tagId") {
                newRecord.push({ typeId: d.typeId, label: d.label, value: false, type: d.type })
            } else {
                let key1Index = newRecord.findIndex(f => f.typeId === d.typeId)
                if (!(key1Index > -1)) {
                    newRecord.push({ typeId: d.typeId, label: d.label || d.displayValue, value: '', type: d.type })
                }
            }
            let checkValue = false;
            storedata.forEach(e => {
                if (d.typeId === e.contactTypeId && d.label !== 'Phone') {
                    let keyIndex = newRecord.findIndex(f => f.typeId === d.typeId)
                    if (keyIndex > -1) {
                        newRecord[keyIndex].value = e.fullName;
                        newRecord[keyIndex].cellNumber = e.cellNumber;
                        newRecord[keyIndex].appointmentDate = e.appointmentDate;
                    }
                } else if (d.typeId === e.substanceTypeId) {
                    let key3Index = newRecord.findIndex(f => f.typeId === d.typeId)
                    if (e.substanceTypeId == enums.lookup.substanceOpioids && d.typeId == e.substanceTypeId) {
                        let opioidsIndex = newRecord.findIndex(f => f.typeId === d.typeId && e.substances === f.value);
                        if (!(opioidsIndex > -1)) {
                            newRecord.push({
                                typeId: d.typeId, label: d.label || d.displayValue, value: e.substances, type: d.type,
                                prescribed: e.prescribed, forWithdrawl: hideWithdrawal.includes(d.displayValue) ? null : e.forWithdrawl
                            })
                        } else {
                            newRecord[opioidsIndex].prescribed = e.prescribed;
                            newRecord[opioidsIndex].forWithdrawl = hideWithdrawal.includes(d.displayValue) ? null : e.forWithdrawl;
                            newRecord[opioidsIndex].value = e.substances;
                        }
                    } else if (key3Index > -1) {
                        newRecord[key3Index].prescribed = e.prescribed;
                        newRecord[key3Index].forWithdrawl = hideWithdrawal.includes(d.displayValue) ? null : e.forWithdrawl;
                        newRecord[key3Index].value = e.substances;
                    }
                }
                else if (d.typeId === e.needTypeId) {
                    let key4Index = newRecord.findIndex(f => f.typeId === d.typeId);
                    let isNeedsCombo = needsCombos.findIndex(el => el == e.needTypeId);
                    if ((isNeedsCombo > -1) && d.typeId == e.needTypeId) {
                        let needsIndex = newRecord.findIndex(f => f.typeId === d.typeId && e.needs === f.value);
                        if (!(needsIndex > -1)) {
                            newRecord.push({
                                typeId: d.typeId, label: d.label || d.displayValue, value: e.needs, type: d.type,
                                referredTo: e.referredTo, referralDate: e.referralDate, familyAcceptedService: e.familyAcceptedService,
                                dayUpdate: e.dayUpdate, customStringValue: d.customStringValue
                            })
                        } else {
                            newRecord[needsIndex].referredTo = e.referredTo;
                            newRecord[needsIndex].referralDate = e.referralDate;
                            newRecord[needsIndex].familyAcceptedService = e.familyAcceptedService;
                            newRecord[needsIndex].dayUpdate = e.dayUpdate;
                            newRecord[needsIndex].value = e.needs;
                            newRecord[needsIndex].customStringValue = d.customStringValue;
                        }
                    } else if (key4Index > -1) {
                        newRecord[key4Index].referredTo = e.referredTo;
                        newRecord[key4Index].referralDate = e.referralDate;
                        newRecord[key4Index].familyAcceptedService = e.familyAcceptedService;
                        newRecord[key4Index].dayUpdate = e.dayUpdate;
                        newRecord[key4Index].value = e.needs;
                        newRecord[key4Index].customStringValue = d.customStringValue;
                    }
                } else if (d.typeId === e.tagId) {
                    let tagIndex = newRecord.findIndex(f => f.typeId === d.typeId);
                    if (tagIndex > -1) {
                        newRecord[tagIndex].value = e.value || e.other;
                        newRecord[tagIndex].comboType = d.comboType;
                    }
                } else if (d.typeId === e.consentTypeId) {
                    if (i % 4 === 0 && !checkValue) {
                        checkValue = true;
                        let key6Index = newRecord.findIndex(f => f.typeId === d.typeId)
                        if (key6Index > -1) {
                            newRecord[key6Index].firstName = e.firstName;
                            newRecord[key6Index].lastName = e.lastName;
                            newRecord[key6Index].updatedDate = e.updatedDate;
                            newRecord[key6Index].signature = e.signature;
                        }
                    }
                }
                else if (d.typeId === e.monitoringTypeId) {
                    let key5Index = newRecord.findIndex(f => f.typeId === d.typeId)
                    if (key5Index > -1) {
                        newRecord[key5Index].updatedDate = e.updatedDate;
                        newRecord[key5Index].notes = e.notes;
                    }

                }
            })
        })
        return newRecord;
    },
    getCaseId: () => {
        const locationParams = window.location.hash.split('/');
        //Get path of caseId from URL
        const caseId = locationParams[locationParams.length - 2];
        return isNaN(Number(caseId)) ? 0 : caseId;
    },
    requiredAlert: () => {
        swal({
            title: "Fill required fields.",
            text: "",
            icon: "error",
            dangerMode: true
        });
    },
    diffYMDHMS(date1, date2) {
        date1 = moment(date1)
        date2 = moment(date2)
        var duration = moment.duration(date2.diff(date1));
        return { hours: duration._data.hours, minutes: duration._data.minutes };
    },
    dynamicsort(property, order) {
        var sort_order = 1;
        if (order === "desc") {
            sort_order = -1;
        }
        return function (a, b) {
            // a should come before b in the sorted order
            if (a[property] < b[property]) {
                return -1 * sort_order;
                // a should come after b in the sorted order
            } else if (a[property] > b[property]) {
                return 1 * sort_order;
                // a and b are the same
            } else {
                return 0 * sort_order;
            }
        }
    },
    pageInfo: { pageNo: 0, pageSize: 10 },
    addIcon: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 6.75H6.75V11.25H5.25V6.75H0.75V5.25H5.25V0.75H6.75V5.25H11.25V6.75Z" fill="#2196F3" />
        </svg>
    ),
    descriptionIcon: (notes) => (<div className={notes ? 'notes-tag' : ''}><svg id="customExpand" width="22" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99998 3.34417L7.90581 6.25L9.19831 4.9575L4.99998 0.75L0.79248 4.9575L2.09415 6.25L4.99998 3.34417ZM4.99998 14.6558L2.09415 11.75L0.801647 13.0425L4.99998 17.25L9.20748 13.0425L7.90581 11.75L4.99998 14.6558Z" fill={notes ? '#008000' : '#0000008a'} />
    </svg></div>),
    openDescriptionIcon: (notes) => (<div className={notes ? 'notes-tag' : ''}><svg id="customExpand" width="22" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 15.0459L1.30089 16.5L4.205 13.2309L7.10911 16.5L8.40084 15.0459L4.205 10.3125L0 15.0459ZM8.41 1.45406L7.10911 0L4.205 3.26906L1.30089 0L0 1.45406L4.205 6.1875L8.41 1.45406Z" fill={notes ? '#008000' : '#0000008a'} fill-opacity="0.54" />
    </svg></div>),
    getConsentData: (field) => {
        let reduxStore = store.getState().appReducer;
        let record = null;
        field.map(e => {
            switch (e.typeId) {
                case enums.consentType.mother:
                    record = reduxStore.motherContact && reduxStore.motherContact[0];
                    if (record && !e.firstName && !e.lastName) {
                        e.firstName = record.firstName;
                        e.lastName = record.lastName;
                    }
                    break;
                case enums.consentType.father:
                    record = reduxStore.fatherContact && reduxStore.fatherContact[0];
                    if (record && !e.firstName && !e.lastName) {
                        e.firstName = record.firstName;
                        e.lastName = record.lastName;
                    }
                    break;
                case enums.consentType.pOSCCoordinator:
                    record = reduxStore.contacts && reduxStore.contacts.filter(rec => rec.contactTypeId === enums.contactType.pOSCCoordinator)
                    if (record && record[0] && !e.firstName && !e.lastName) {
                        let fullName = record[0].fullName;
                        if (fullName) {
                            fullName = fullName.split(' ');
                            e = Object.assign(e, { firstName: fullName[0] })
                            if (fullName.length > 1) {
                                fullName.splice(0, 1);
                                e.lastName = fullName.join(' ');
                            }
                        }
                    }
                    break;
                case enums.consentType.otherCaregiver:
                    record = reduxStore.careGiverContact && reduxStore.careGiverContact[0];
                    if (record && !e.firstName && !e.lastName) {
                        e.firstName = record.firstName;
                        e.lastName = record.lastName;
                    }
                    break;
            }
        })
        return field;
    },
    getlabelValuefromCombo: (data) => {
        const combos = store.getState().appReducer.comboData;
        data && data.map(e => {
            if (e.customStringValue && e.value) {
                let comboArray = combos && combos[enums.lookupType[JSON.parse(e.customStringValue).comboType]] || [];
                let selectedArray = e.value.split(',')
                e.label = '';
                comboArray.map(x => {
                    if (selectedArray.indexOf(x.value.toString()) > -1) {
                        e.label = e.label + (e.label && ',') + x.label;
                    }
                })
            }
        })
        return data
    },
    mobileAppBaseUrl: mobileAppBaseUrl,
    setLoginData: (response, dispatch) => {
        dispatch({ type: 'SET_USER_DATA', userData: response.data });
        dispatch({ type: 'SET_FIELDS', formFields: response.fields });
        dispatch({ type: 'SET_COMBO_DATA', comboData: response.comboData });
        dispatch({ type: 'SET_MESSAGE_DATA', message: response.message });
    },
    setRecentMessage: (response, dispatch) => {
        axios({
            method: 'POST', url: `${videoCallAPIEndPoints.getRecentConversation}`,
            data: { userId: response.data.id, requestFrom: 'web' }
        }).then(data => {
            let recentConversation = data.data && data.data.conversation || [];
            recentConversation.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0));
            dispatch({ type: 'SET_RECENT_CONVERSATION', recentConversation: recentConversation });
        })

        axios({
            method: 'POST', url: `${videoCallAPIEndPoints.unreadMsgsAndMissedCallCount}`,
            data: {
                userId: response.data.id,
                requestFrom: "web"
            }
        }).then(e => {
            if (e.data) {
                const totalUnreads = Number(e.data.totalUnreadMsgs) + Number(e.data.totalUnreadMissedVideoCalls);
                dispatch({ type: 'SET_UNREAD_COUNT', unreadCount: (totalUnreads || 0) });
            }
        });
    },
    capitalize: (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    },
    videoCallAPIEndPoints: videoCallAPIEndPoints,
    callIcon: (
        <svg width="10" height="10" viewBox="0 0 12 12" style={{ marginTop: '.3em' }} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.41333 5.19333C3.37333 7.08 4.92 8.62 6.80667 9.58667L8.27333 8.12C8.45333 7.94 8.72 7.88 8.95333 7.96C9.7 8.20667 10.5067 8.34 11.3333 8.34C11.7 8.34 12 8.64 12 9.00667V11.3333C12 11.7 11.7 12 11.3333 12C5.07333 12 0 6.92667 0 0.666667C0 0.3 0.3 0 0.666667 0H3C3.36667 0 3.66667 0.3 3.66667 0.666667C3.66667 1.5 3.8 2.3 4.04667 3.04667C4.12 3.28 4.06667 3.54 3.88 3.72667L2.41333 5.19333Z" fill="#9E9E9E" />
        </svg>
    )
}