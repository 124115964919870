import React, { useEffect, useRef } from 'react';

const Player = ({ url, ...props }) => {
    const audioRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;
        audio.pause();
        if (!url) {
            return;
        }
        audio.load();
        audio.play();
    }, [url]);

    return (
        <audio ref={audioRef} {...props}>
            <source src={url} />
        </audio>
    );
};

export default Player;