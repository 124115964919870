const actionType = {
    LOGIN: 'LOGIN',
    RESET_STATE: 'RESET_STATE',
    SET_FORM_DATA: 'SET_FORM_DATA',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_DIALOG_DATA: 'SET_DIALOG_DATA',
    SET_FIELDS: 'SET_FIELDS',
    SET_TITLE_NAME: 'SET_TITLE_NAME',
    SET_TOP_SEARCH: 'SET_TOP_SEARCH',
    SET_TASK_LIST: 'SET_TASK_LIST',
    SET_PROFILE_DATA: 'SET_PROFILE_DATA',
    SET_CASE_LIST: 'SET_CASE_LIST',
    SET_APPOINTMENT_LIST: 'SET_APPOINTMENT_LIST',
    SET_ACTIVE_CASEID: 'SET_ACTIVE_CASEID',
    SET_COMBO_DATA: 'SET_COMBO_DATA',
    SET_SHOW_LOADER: 'SET_SHOW_LOADER',
    SET_CHART_DATA: 'SET_CHART_DATA',
    SET_CHART_DATA_TASKLIST: 'SET_CHART_DATA_TASKLIST',
    SET_CHART_DATA_APPOINTMENTLIST: 'SET_CHART_DATA_APPOINTMENTLIST',
    CLEAR_PROFILE_DATA: 'CLEAR_PROFILE_DATA',
    SET_SAVE_DATE_STATUS: 'SET_SAVE_DATE_STATUS',
    SET_STATUSLOG_LIST: 'SET_STATUSLOG_LIST',
    SET_SUPPORTDOC_LIST: 'SET_SUPPORTDOC_LIST',
    SET_SUPPORTPRO_LIST: 'SET_SUPPORTPRO_LIST',
    SET_SIBLING_DATA: 'SET_SIBLING_DATA',
    SET_ACTIVITY: 'SET_ACTIVITY',
    LOAD_PROFILE_DATA: 'LOAD_PROFILE_DATA',
    SET_AWARD_LIST: 'SET_AWARD_LIST',
    SET_SUPPORT_DOCUMENTS: 'SET_SUPPORT_DOCUMENTS',
    SET_FORM_MONITORING_REMOVE: 'SET_FORM_MONITORING_REMOVE',
    SET_ACTIVITY_DATA: 'SET_ACTIVITY_DATA',
    SET_APPOINTMENT_DATA: 'SET_APPOINTMENT_DATA',
    SET_PAGING: 'SET_PAGING',
    SET_SCROLL: 'SET_SCROLL',
    SET_CHART_FILTERS: 'SET_CHART_FILTERS',
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    SET_NOTIFICATION_DATA: 'SET_NOTIFICATION_DATA',
    SET_MESSAGE_DATA: 'SET_MESSAGE_DATA',
    SET_CHAT_DATA: 'SET_CHAT_DATA',
    SET_RECENT_CONVERSATION: 'SET_RECENT_CONVERSATION',
    OPEN_VIDEO_CALL: 'OPEN_VIDEO_CALL',
    SET_UNREAD_COUNT: 'SET_UNREAD_COUNT',
    RESET_CASE: 'RESET_CASE',
    SET_CASE_NOTE: 'SET_CASE_NOTE',
    SET_SUPPORT_PERSON_DATA: 'SET_SUPPORT_PERSON_DATA',
    SET_SUBSTANCE_OTHER_DATA: 'SET_SUBSTANCE_OTHER_DATA',
    SET_REPORT_LIST: 'SET_REPORT_LIST'
};

export default actionType;