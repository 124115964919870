module.exports = {
  DateRange: {
    startDate: Date,
    endDate: Date,
  },
  DefinedRange: {
    startDate: Date,
    endDate: Date,
    label: String
  },
  NavigationAction: {
    // eslint-disable-next-line no-unused-vars
    Previous: -1,

    // eslint-disable-next-line no-unused-vars
    Next: 1
  }
}

//export type Setter = React.Dispatch<React.SetStateAction((value) => value);
