import React, { useRef, forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import actionTypes from '../redux/actions/actionType';
import { request, apis } from '../httpUtil';
import { history } from '../redux/store/';
import { ACTION_API } from '../Utility/const';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import TablePagination from '@material-ui/core/TablePagination';
import util from '../util';
import Calendar from '../components/Calendar/Calendar';
import '../components/Calendar/Calendar.css';
import GetAppIcon from '@material-ui/icons/GetApp';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, Grid, IconButton } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

const moment = require('moment');


const noteIconStyle = { marginLeft: '0px', fontSize: '22px', position: 'absolute', right: '26px', color: '#0fa985' }
const nonoteIconStyle = { marginLeft: '0px', fontSize: '22px', position: 'absolute', right: '26px', color: '#a2a2a2' }

let dispatch, saveNotes, textInput, textareaField, autoSaveTimeout, changeRowSelection, onRowDelete;

let seletedDateTime = [];

const downloadAction = {
    icon: () => <div style={{ position: 'relative' }}><GetAppIcon /></div>,
    tooltip: 'Download',
    onClick: async (event, rowData) => {
        const documentType = rowData.hasOwnProperty('programName') ? 'supportprogram' : 'supportdocument';
        const url = `${apis.attachment}?action=download&documentId=${rowData.id}&documentType=${documentType}`;
        window.open(url);
    }
}
// For Use filter col Data to Add / Update to send Api 
const collectFilterColData = (cols = [], record) => {
    const filtterRecord = {};
    cols.forEach(col => {
        if (col.field !== 'updatedAt' && col.field !== 'createdBy') {
            filtterRecord[col.field] = record[col.field]
        }

        if (col.field == 'duration' && !record[col.field]) {
            filtterRecord[col.field] = '00'
        }

        if (col.field == 'appointmentDateTimeEnd') {
            if (record['duration'] && record['appointmentDateTime']) {
                filtterRecord[col.field] = Date.parse(moment(record['appointmentDateTime']).add(record['duration'], 'minutes').format(util.dateTimeAMPM));
            } else {
                filtterRecord[col.field] = Date.parse(moment(record['appointmentDateTime']).format(util.dateTimeAMPM))
            }
        }

    });

    if (record.id > 0)
        filtterRecord['id'] = record.id;

    return filtterRecord;
}
const updateNote = (e) => { // Display Note Editable       
    if (e.currentTarget.nextSibling) {
        e.currentTarget.nextSibling.style.display = 'block';
        e.currentTarget.style.display = 'none';
    }
}
const onChangeNote = (row) => {
    if (autoSaveTimeout) {
        clearTimeout(autoSaveTimeout);
    }
    let textAreaDiv = textareaField.current;
    if (textAreaDiv) {
        textInput.current.innerText = textAreaDiv.value;
    }
    let regx = /[<>]/g;
    if (regx.test(textAreaDiv.value)) {
        textAreaDiv.value = textAreaDiv.value.replace(regx, '');
    }
    autoSaveTimeout = setTimeout(() => { saveNotes(row, textAreaDiv.value) }, 1000);
}
// render notes
const renerNotes = rowData => {
    let note = rowData && rowData.notes.replace(/\r?\n/g, '<br/>');
    return (
        <>
            <div className="expand-area" onClick={event => updateNote(event)} style={!note ? { display: 'none' } : { display: 'block' }}>
                <p ref={textInput} dangerouslySetInnerHTML={{ __html: note }} ></p>
            </div>
            <div className="expand-area" style={note ? { display: 'none' } : { display: 'block' }}>
                <textarea name="notes" autoFocus cols="50" style={{ width: '100%', height: 75 }} ref={textareaField} onChange={() => onChangeNote(rowData)}>{rowData.notes}</textarea>
            </div>
        </>
    )
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        background: '#EDEDED',
        borderRadius: '5px',
        marginLeft: '1em',
        position: 'absolute',
        zIndex: '1',
        marginTop: '5em'

    },
    searchIcon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        color: '#676767'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    gridBtnCont: {
        textAlign: 'right'
    }
}));

const List = (props) => {
    const [dateValue, setDateValue] = useState(null);
    let clickedTimeout = null;
    let { showForm, onAdd, openFormClick, formRoute, routeTitle, columns, activeCaseId, isEditable, actions = [], showDownload, isExpand, options,
        listOption = {}, showDateSearch, fromDashboard, searchValue, getToolbar, isCase, className, defaultSort, sortDir, addTitle,
        tableHeader, handleSearch, fromAward, fromReport, isShowPaging = true, disableSort = false } = props;
    const gridPaging = useSelector(state => state.appReducer.gridPaging);
    const userData = useSelector(state => state.appReducer.userData);
    const [pageInfo, setPageInfo] = useState({ pageNo: 0, pageSize: 10, orderBy: defaultSort, dir: sortDir });
    const [selectedRow, setSelectedRow] = useState();
    const [clicked, setClicked] = useState(false);
    const [search, setSearch] = useState('');
    let optionsProps = {
        sorting: !disableSort,
        search: false,
        title: false,
        showTitle: false,
        toolbar: false,
        filtering: false,
        pageSize: isCase ? gridPaging.pageSize : props.defaultPageSize ? props.defaultPageSize : 10,
        paging: isShowPaging,
        pageSizeOptions: [5, 10, 20, 50],
        debounceInterval: 1000,
        actionsCellInsideStyle: { alignItems: 'left', justifyContent: 'left' },
        actionsColumnIndex: -1,
        headerStyle: { backgroundColor: '#2196f3', color: '#FFF', overflowWrap: 'break-word', position: 'sticky', top: 0 },
        maxBodyHeight: `2576px`, //on case saection 495px
        detailPanelColumnAlignment: 'right',
        emptyRowsWhenPaging: false,
        detailPanelType: 'single'
    }

    let maxBodyHeight = isCase ? '2576px' : '2576px';
    optionsProps.maxBodyHeight = maxBodyHeight;
    let selectedBgColor = '#fff';

    const { api } = listOption;
    if (options) {
        optionsProps = { ...optionsProps, ...options }
    }
    let currSelectedRow = { ...selectedRow };
    if (optionsProps) {
        optionsProps.rowStyle = rowData => {
            if ((!currSelectedRow || !currSelectedRow.tableData) && isCase) {
                if (gridPaging.selectedRow && gridPaging.pageNo === gridPaging.selectedRow.pageNo) {
                    currSelectedRow = gridPaging.selectedRow.row;
                }
            }
            if (isCase) {
                selectedBgColor = (currSelectedRow && currSelectedRow.caseId && currSelectedRow.caseId === rowData.caseId) ? 'rgba(250, 229, 101, 0.67)' : '#FFF'
            } else {
                selectedBgColor = (currSelectedRow && currSelectedRow.tableData && currSelectedRow.tableData.id === rowData.tableData.id) ? 'rgba(250, 229, 101, 0.67)' : '#FFF'
            }
            return ({ backgroundColor: selectedBgColor })
        };
    }
    textInput = useRef();
    textareaField = useRef();
    dispatch = useDispatch();
    // select data from store 
    let rowData = useSelector(state => state.appReducer[listOption.listName]);

    useEffect(() => {
        getData(props);
    }, [pageInfo, searchValue, showForm, searchValue && searchValue.dateRange, gridPaging.pageNo, gridPaging.pageSize, gridPaging.orderBy, gridPaging.dir, gridPaging.routeTitlesearchValue], pageInfo.pageSize)

    if (!rowData) {
        rowData = {
            total: 0,
            records: []
        }
    }
    changeRowSelection = (data, notUpdate) => {
        if (!notUpdate) {
            dispatch({ type: actionTypes.SET_PAGING, gridPaging: { ...gridPaging, ...{ selectedRow: { pageNo: gridPaging.pageNo, row: data } } } });
        }
        setSelectedRow(data)
    }
    const onRowClick = (event, data, togglePanel) => {
        let { nearestViewportElement, id, type, children } = event.target;
        let childNode = children && children[0] && children[0].children && children[0].children[0] && children[0].children[0].id || '';
        if (id === "customExpand" || nearestViewportElement && nearestViewportElement.id === "customExpand" || childNode === "customExpand") {
            togglePanel();
            return;
        }
        if (clicked) {
            setClicked(false);
            clickedTimeout && clearTimeout(clickedTimeout);
            history.push(formRoute + data.caseId);
            if (routeTitle) {
                dispatch({ type: 'SET_TITLE_NAME', appTitleName: routeTitle });
            }
            dispatch({ type: actionTypes.SET_ACTIVE_CASEID, activeCaseId: data.caseId });
            dispatch({ type: actionTypes.SET_PROFILE_DATA, updatedData: {} });

        }
        else {
            let { innerText, TEXT_NODE } = event.target;
            Object.keys(data).map(d => {
                if (d === 'motherName' && data[d] === innerText && [3, 4].includes(TEXT_NODE)) {
                    history.push(`/assessment/${data.caseId}/task`)
                    dispatch({ type: actionTypes.SET_ACTIVE_CASEID, activeCaseId: data.caseId });
                    dispatch({ type: actionTypes.SET_PROFILE_DATA, updatedData: {} });
                    dispatch({ type: actionTypes.SET_ACTIVE_TAB, activeTab: 2 });
                }

            });
            setClicked(true);
            clickedTimeout = setTimeout(() => {
                changeRowSelection(data);
                setClicked(false);
            }, 500);
        }
    }
    // Add Button Icon
    const tableIcons = {
        Add: forwardRef((props, ref) => <Button variant="contained" style={{ color: '#FFF' }} onClick={() => {
            optionsProps.maxBodyHeight = `${(Number(optionsProps.maxBodyHeight.slice(0, optionsProps.maxBodyHeight.length - 2)) + 58)}px`
        }}> Add + </Button>)
    }
    const localization = {
        pagination: { labelRowsSelect: "Items Per Page", labelDisplayedRows: rowData && rowData.total ? '{from} - {to} of {count} items' : '0 - {to} of {count} items' }
    }
    // fetch data
    const getData = async (props, isComingFromDeleted) => {
        const { listOption, activeCaseId, fromDashboard, searchValue } = props;
        const { api = '', reduxActions, customAction = '' } = listOption || {};
        const reduxChartActions = listOption && listOption.listName ? `${actionTypes.SET_CHART_DATA}_${listOption.listName.toUpperCase()}` : null;
        //Set grid config after delete item from grid from last page
        if (isComingFromDeleted && rowData && rowData.records && rowData.records.length < 2 && (gridPaging.pageNo > 0 || pageInfo.pageNo > 0)) {
            gridPaging.pageNo = gridPaging.pageNo - 1;
            dispatch({ type: actionTypes.SET_PAGING, gridPaging: { ...gridPaging, ...{ pageNo: gridPaging.pageNo } } });
            pageInfo.pageNo = pageInfo.pageNo - 1;
            setPageInfo({ ...pageInfo, pageNo: pageInfo.pageNo });
        }
        if (api) {
            dispatch({ type: actionTypes.SET_SHOW_LOADER, showLoader: true });
            let dataParams = {
                pageSize: isCase ? gridPaging.pageSize : pageInfo.pageSize,
                pageNo: isCase ? gridPaging.pageNo : pageInfo.pageNo,
                orderBy: isCase ? gridPaging.orderBy : pageInfo.orderBy,
                dir: isCase ? gridPaging.dir : pageInfo.dir,
                searchValue: isCase ? gridPaging.searchValue : searchValue,
                fromDashboard: fromDashboard
            };
            const params = Object.assign({ action: customAction || 'list', caseId: activeCaseId }, dataParams);
            const response = await request(api, params);
            if (response && response.success) {
                dispatch({ type: reduxActions, fetchRecord: response });
                seletedDateTime = response && response.dateTime || []
                if (response.chartData) {
                    dispatch({ type: reduxChartActions, chartData: response.chartData, dateTime: response.dateTime || [] });
                }
            } else {
                util.listErrorAlert(response.error);
            }
            dispatch({ type: actionTypes.SET_SHOW_LOADER, showLoader: false });
        }
    }
    const deleteAction = {
        icon: isCase ? 'folder_outline' : 'delete_outline',
        tooltip: isCase ? 'Archive' : 'Delete',
        onClick: (event, rowData) => {
            changeRowSelection(rowData)
            swal({
                title: `Do you want to ${isCase ? 'archive' : 'delete'} this record?`,
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        onRowDelete(rowData);
                    }
                    changeRowSelection(undefined)
                });
        }
    }
    const monthChange = (e) => {
        dispatch({ type: actionTypes.SET_CHART_FILTERS, chartFilter: { appointmentDate: moment(e.activeStartDate) } });
        getData({ ...props, ...{ searchValue: { value: moment(e.activeStartDate).format('YYYY-MM-DD'), type: "month" } } });
    }
    const handleDateChange = (e) => {
        if (!moment(moment(e).format(util.dateFormat)).isSame(moment(moment(dateValue).format(util.dateFormat)))) {
            dispatch({ type: actionTypes.SET_CHART_FILTERS, chartFilter: { appointmentDate: moment(e, 'YYYY-MM-DD[T]HH:mm:ss') } });
            getData({ ...props, ...{ searchValue: { value: moment(e, 'YYYY-MM-DD[T]HH:mm:ss').format('YYYY-MM-DD'), type: "day" } } });
        }
        setDateValue(e);
    }
    // handle sorting
    const onOrderChange = (orderedColumnId, orderDirection) => {
        const orderBy = orderedColumnId >= 0 ? columns[orderedColumnId].field : props.defaultSort;
        const dir = orderedColumnId >= 0 ? orderDirection : props.sortDir;
        setPageInfo({ ...pageInfo, orderBy: orderBy, dir: dir });
        dispatch({ type: actionTypes.SET_PAGING, gridPaging: { ...gridPaging, ...{ orderBy: orderBy, dir: dir } } });
    }
    // handle paging
    const onHandleChangeListPage = (page) => {
        changeRowSelection(null, true);
        setPageInfo({ ...pageInfo, pageNo: page });
        dispatch({ type: actionTypes.SET_PAGING, gridPaging: { ...gridPaging, ...{ pageNo: page } } });
    }
    // handle page size
    const handleChangeRowsPerPage = (e, scope) => {
        let pageSize = scope ? e.target.value : e;
        if (scope) {
            scope.onChangeRowsPerPage(e);
        }
        changeRowSelection(null, true);
        setPageInfo({ ...pageInfo, pageSize: pageSize });
        dispatch({ type: actionTypes.SET_PAGING, gridPaging: { ...gridPaging, ...{ pageSize: pageSize } } });
    }
    // auto save notes data
    saveNotes = async (row, value) => {
        if (value) {
            await request(api, { action: 'save', data: { id: row.id, notes: value } });
            rowData.records.map(e => {
                if (e.tableData && (e.tableData.id == row.tableData.id)) {
                    e.notes = value;
                }
            });
        }
    }
    const onRowAdd = async (newData, listname) => {
        return new Promise(async (resolve, reject) => {
            if (listname === 'taskList' && !(newData.time != undefined && newData.activityPriorityId != undefined)) {
                util.requiredAlert();
                return reject();
            }

            if (listname === 'appointmentList' && !(newData.appointmentDateTime != undefined && newData.appointmentTypeId != undefined)) {
                util.requiredAlert();
                return reject();
            }

            const filterData = collectFilterColData(columns, newData);
            filterData.caseId = activeCaseId;
            const responseAdd = await request(api, { action: 'Save', data: filterData });
            if (responseAdd && responseAdd.success) {
                getData(props);
            }
            if (!responseAdd.success) {
                util.listErrorAlert(responseAdd.error);
            }

            return resolve()
        })

    }
    const onRowUpdate = async (newData, listname) => {
        return new Promise(async (resolve, reject) => {
            if (listname === 'taskList' && !(newData.time != undefined && newData.activityPriorityId != undefined)) {
                util.requiredAlert();
                return reject();
            }

            if (listname === 'appointmentList' && !(newData.appointmentDateTime != undefined && newData.appointmentTypeId != undefined)) {
                util.requiredAlert();
                return reject();
            }

            const filterData = collectFilterColData(columns, newData);
            if (listname === 'taskList' && newData.caseActivityId) {
                filterData.id = newData.caseActivityId;
            }

            if (listname === 'appointmentList' && newData.appointmentId) {
                filterData.id = newData.appointmentId;
            }

            const responseUpdate = await request(api, { action: 'Save', data: filterData });
            if (responseUpdate && responseUpdate.success) {
                getData(props);
            }
            if (!responseUpdate.success) {
                util.listErrorAlert(responseUpdate.error);
            }
            return resolve()
        })
    }
    const onCheckBoxChange = (e) => {
        searchValue = e.currentTarget.checked;
    }
    onRowDelete = async oldData => {
        const responseDelete = await request(api, {
            action: ACTION_API.DELETE, data: {
                id: oldData.id ? oldData.id : oldData.caseActivityId ? oldData.caseActivityId : oldData.appointmentId ? oldData.appointmentId : 0,
                caseId: oldData.caseId
            }
        });
        if (responseDelete && responseDelete.success) {
            swal(`Record has been ${isCase ? 'archived' : 'deleted'}!`, { icon: "success", });
            getData(props, true);
        }
        if (!responseDelete.success) {
            util.listErrorAlert(responseDelete.error);
        }
    }
    if (showDownload) {
        actions = [downloadAction];
    } else {
        if (!!actions) {
            const actionIndex = actions && actions.filter(d => isCase ? d.icon === 'folder_outline' : d.icon === "delete_outline");
            if ((actionIndex.length == 0)) {
                isCase ? actions.push(deleteAction) : actions.unshift(deleteAction);
            }
        }
    }
    // actions = fromDashboard ? actions.filter(e => typeof (e) === 'function') : actions;
    let totalRecords = (rowData && rowData.total) || 0;

    if (totalRecords < 5) {
        if (totalRecords === 1) {
            optionsProps.maxBodyHeight = '214px'
        } else {
            optionsProps.maxBodyHeight = `${(Number(optionsProps.maxBodyHeight.slice(0, optionsProps.maxBodyHeight.length - 2)) + 58)}px`
        }
    } else {
        optionsProps.maxBodyHeight = maxBodyHeight
    }
    const [resetCal, setResetCal] = useState(false)
    const reSetCalandar = () => {
        getData({ ...props, ...{ searchValue: '' } });
        dispatch({ type: actionTypes.SET_CHART_FILTERS, chartFilter: { appointmentDate: null } });
        setResetCal(true)
    }
    columns && columns.forEach(col => {
        if (col.type === "string") {
            col.customSort = (a) => {
                const value = a[isCase && gridPaging.orderBy ? gridPaging.orderBy : pageInfo.orderBy];
                return value && typeof (value) === 'string' ? value.toLowerCase() : value;
            }
        }
    });

    const updateResetCall = () => {
        setResetCal(false)
    }

    const classes = useStyles();
    const onSearch = (event) => {
        let { value } = event.target;
        setSearch(value)
    }    
    const rowsPerPage = isCase ? gridPaging.pageSize : pageInfo.pageSize;
    return <div style={{ flex: 1 }}>
        <Paper>
            <TableContainer className={`table-expand-wrapper ${className || ''} ${isExpand && 'expand-row'}`}>
                {showDateSearch ? <div style={{ position: 'relative' }}>
                    <Calendar updateResetCall={updateResetCall} resetCal={resetCal} selectedDates={seletedDateTime || []} value={dateValue} style={{ width: window.innerWidth }} className="cal" onChange={handleDateChange} onActiveDateChange={monthChange} />
                </div> : null}
                {(!isCase && handleSearch) && <div className={classes.search}>
                    <form autoComplete="off" onSubmit={(e) => handleSearch(e, search)} >
                        <InputBase
                            placeholder="Search"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            onChange={onSearch}
                            onBlur={(e) => handleSearch(e, search)}
                            key='search'
                            inputProps={{ 'aria-label': 'search' }}
                            defaultValue={searchValue && searchValue.searchPramas || ''}
                        />
                        <IconButton type="submit" aria-label="search">
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                        </IconButton>
                    </form>
                </div>}
                <MaterialTable
                    icons={tableIcons}
                    id={Math.random()}
                    totalCount={totalRecords}
                    columns={columns || []}
                    minRows={0}
                    components={{
                        Pagination: props => {
                            return (
                                <>
                                   <TablePagination
                                        {...props}
                                        //ActionsComponent={props.ActionsComponent}
                                        // SelectProps={props.SelectProps}
                                        // classes={props.classes}
                                        // icons={props.icons}
                                        // rowsPerPageOptions={optionsProps.pageSizeOptions}
                                        // count={totalRecords}
                                        // rowsPerPage={rowsPerPage}
                                        // page={props.page}
                                        // onChangePage={(e, page) => onHandleChangeListPage(page)}
                                        onChangeRowsPerPage={e => handleChangeRowsPerPage(e, props)}
                                    />
                                </>
                            )
                        },
                        Toolbar: props => {
                            return (
                                <div>
                                    <div className="paper-header-text br-b">{tableHeader}</div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} container>
                                            <Grid item xs={7} md={8} lg={9} >
                                                <MTableToolbar {...props} />
                                                {
                                                    getToolbar && getToolbar()
                                                }

                                                {onAdd && <Button className={!showDateSearch ? 'resetButtonCase' : "add-Btn"} variant="contained" onClick={onAdd} >
                                                    <span className="pdr-5">
                                                        {util.addIcon}
                                                    </span>
                                                    {addTitle || 'Add'}</Button>}
                                                {showDateSearch &&
                                                    <div>
                                                        <Button className='resetButtonCase' onClick={reSetCalandar} variant="contained" style={{ color: '#FFF' }}>View All</Button>
                                                    </div>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        }
                    }}
                    onRowClick={openFormClick ? openFormClick : onRowClick}
                    page={isCase ? gridPaging.pageNo : pageInfo && pageInfo.pageNo}
                    data={(rowData && rowData.records) || []}
                    options={optionsProps}
                    onOrderChange={onOrderChange}
                    onChangePage={onHandleChangeListPage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    detailPanel={isExpand ? [rowData => ({
                        icon: () => '',
                        tooltip: '',
                        openIcon: () => '',
                        render: renerNotes,
                    })] : []}
                    localization={localization}
                    actions={fromAward || fromReport ? [] : actions}
                    editable={isEditable ? {
                        onRowAdd: data => onRowAdd(data, listOption.listName),
                        onRowUpdate: data => onRowUpdate(data, listOption.listName)
                    } : null}
                />
            </TableContainer>
        </Paper>
    </div>
}
export default React.memo(List);