import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Typography, Popover, makeStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { styles } from '../../navigation/styles';
import actions from '../../redux/actions/actionType';
import moment from 'moment';
import axios from 'axios';
import util from '../../util';
import { enums } from '../../Utility/const';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '32em',
        borderBottom: '1px solid #E5E5E5',
        padding: '0',
        backgroundColor: theme.palette.background.paper,
        '& .MuiListItemText-primary': {
            fontWeight: '500',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.5px',
            display: 'flex',
            alignItems: 'center'
        }
    },
    typography: {
        padding: '1em 1em 0.5em 1.5em',
        width: '29em',
        borderBottom: '1px solid #E5E5E5'
    },
    notificationText: {
        fontWeight: '500',
        fontSize: '18px',
        marginLeft: '.5em'
    },
    lastMessage: {
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
        letterSpacing: '0.4px',
        width: '95%'
    },
    popover: {
        '& .MuiPopover-paper': {
            maxHeight: '28em',
            top: '57px !important',
            right: '60px !important',
            left: 'auto !important'
        }
    },
    time: {
        fontSize: '12px',
        letterSpacing: '0.4px',
        width: '20%',
        textAlign: 'right',
        fontWeight: '400'
    },
    notSeen: {
        background: '#2196F3',
        borderRadius: '50%',
        width: '12px',
        height: '12px',
        marginTop: '.2em'
    },
    newMessage: {
        fontSize: '12px',
        color: '#2196F3',
        display: 'flex',
        width: '34%'
    },
    seenTime: {
        color: 'rgba(0, 0, 0, 0.24)'
    },
    newMessageCount: {
        background: '#2196F3',
        color: '#fff',
        borderRadius: '10px',
        fontSize: '12px',
        padding: '0px 11px',
        marginLeft: '1em',
        marginTop: '4px',
        height: '19px'
    },
    unreadCount: {
        marginRight: '.5em'
    }
}));

const newDate = moment().format(util.dateFormat);
const preDate = moment().subtract(1, 'days').format(util.dateFormat);
const anchorOrigin = { vertical: 'bottom', horizontal: 'center' }
const transferOrigin = { vertical: 'top', horizontal: 'center' }
const Message = (props) => {
    const dispatch = useDispatch();
    const message = useSelector(state => state.appReducer.message);
    const recentConversation = useSelector(state => state.appReducer.recentConversation);
    const userData = useSelector(state => state.appReducer.userData);
    const unReadMessageCount = useSelector(state => state.appReducer.unreadCount) || 0;
    const classes = useStyles();

    const getConversation = (data) => {
        return new Promise(function (resolve, reject) {
            let appUserId = data.users.length > 0 ? data.users[0].id : null;
            if (data.id) {
                axios({
                    method: 'POST', url: `${util.videoCallAPIEndPoints.getChatAndCallHistory}?roomId=${data.id}`,
                    data: {
                        userId: userData.id,
                        requestFrom: 'web'
                    }
                }).then(conversationData => {
                    let resData = conversationData.data || {};
                    resData.roomId = data.id;
                    resData.appuserId = appUserId;
                    resData.webUserId = userData.id;

                    setTimeout(() => {
                        document.getElementById('chatBody') && document.getElementById('chatBody').lastElementChild.scrollIntoView();
                    }, 1000)
                    resolve(resData);
                })
            }
        })
    }

    const markedAsRead = async (data) => {
        if (data.id) {
            await axios({
                method: 'POST', url: `${util.mobileAppBaseUrl}/videoCallActivity/${data.id}/mark-read`,
                data: {
                    userId: userData.id,
                    requestFrom: 'web'
                }
            })

            //chat/unReadCount
            axios({
                method: 'POST', url: `${util.videoCallAPIEndPoints.unreadMsgsAndMissedCallCount}`,
                data: {
                    userId: userData.id,
                    requestFrom: "web"
                }
            }).then(e => {
                if (e.data) {
                    const totalUnreads = Number(e.data.totalUnreadMsgs) + Number(e.data.totalUnreadMissedVideoCalls);
                    dispatch({ type: actions.SET_UNREAD_COUNT, unreadCount: (totalUnreads || 0) });
                }
            });
        }
    }

    const onChatClick = async (data) => {
        let conversationData = await getConversation(data);
        props.onMessageClose();
        conversationData.openChat = true;
        conversationData.sendMessageAnchorEl = props.messageAnchorEl;
        dispatch({ type: actions.SET_CHAT_DATA, chatData: conversationData });
        await markedAsRead(data);
    }
    const MissedCallMessage = ({ className, userType, status }) => {
        let message = '';
        switch (status) {
            case enums.lookup.unansweredVideoCall:
                message = userType == "web" ? 'No Answer' : 'Missed call';
                break;
            case enums.lookup.answeredVideoCall:
                message = userType == "web" ? 'Outgoing Call' : 'Incoming Call';
                break;
            case enums.lookup.rejectedVideoCall:
                message = userType == "web" ? 'No Answer' : 'Rejected';
                break;
        }
        return (
            <div className={className}>
                {`${userType == "web" ? 'You: ' : ''}${message}`}
            </div>
        )
    }
    return (
        <Popover
            id={props.messageId}
            open={props.openMessage}
            anchorEl={props.messageAnchorEl}
            onClose={props.onMessageClose}
            anchorOrigin={anchorOrigin}
            className={classes.popover}
            transformOrigin={transferOrigin}
        >
            <Typography className={classes.typography}>
                <div className="flex">
                    <span>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.9 4.4H18.7V14.3H4.4V16.5C4.4 17.105 4.895 17.6 5.5 17.6H17.6L22 22V5.5C22 4.895 21.505 4.4 20.9 4.4ZM16.5 11V1.1C16.5 0.495 16.005 0 15.4 0H1.1C0.495 0 0 0.495 0 1.1V16.5L4.4 12.1H15.4C16.005 12.1 16.5 11.605 16.5 11Z" fill="black" fill-opacity="0.87" />
                        </svg>
                    </span>
                    <div className="full-width flex">
                        <div className={classes.notificationText}>Messages</div>
                        <div className={classes.newMessageCount}>{unReadMessageCount} new</div>
                    </div>
                </div>
            </Typography>
            {recentConversation.length > 0 && recentConversation.map((el, index) => {
                return (
                    <List className={classes.root} key={index}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt={el.name} src="" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                        <div className='width-80'>
                                            <span className='cursor' onClick={() => onChatClick(el)}>{el.users[0] && el.users[0].name}</span>
                                        </div>
                                        <div className={`${classes.time} ${el.isSeen && classes.seenTime}`}>
                                            {newDate == moment(el.createdAt).format(util.dateFormat) ? moment(el.createdAt).format("hh:mm A") : preDate == moment(el.createdAt).format(util.dateFormat) ? 'Yesterday' : `${moment().diff(moment(el.createdAt), 'days')} days ago`}
                                        </div>
                                    </>
                                }
                                secondary={
                                    <div className="flex">
                                        {
                                            el.type == 'call' ? <MissedCallMessage className={`${classes.lastMessage}`} userType={el.postedUserType} status={Number(el.status)} /> :
                                                <div className={`${classes.lastMessage}`}>
                                                    {`${el.postedUserType == "web" ? 'You: ' : ''}${el.message}`}
                                                </div>
                                        }
                                        {(el.postedUserType != "web" && el.readByUser == null) &&
                                            <>
                                                <div className={classes.unreadCount}>{el.unreadCount ? `+ ${el.unreadCount}` : ''}</div>
                                                <div className={classes.notSeen} ></div>
                                            </>
                                        }
                                    </div>
                                }
                            />
                        </ListItem>
                    </List>
                )
            })}
        </Popover>
    )
}
export default withStyles(styles, { withTheme: true })(React.memo(Message)); 