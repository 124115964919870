

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const BlueCheckBox = withStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.54)',
        '&$checked': {
            color: '#54b3ff',
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

// Base CheckBox component
const BaseCheckBox = React.memo(function BaseCheckBox(props) {
    const { data, handleChange, labelalign, records } = props;
    let { name, typeId, key, newId, isMultiDropDown, autoAddRow } = data;
    let checked = false;
    if (records) {
        const rec = autoAddRow ? records.find(rec => (Number(rec[key]) === typeId) && (rec['newId'] == newId || rec['id'] === newId)) : records.find(rec => Number(rec[key]) === typeId);
        if (rec) {
          // #378
        checked = rec.value || rec[name];
        }
    }
    data.checked = checked == "false" ? false : checked || false;
    return (<div className="checkbox-area">
        {
            labelalign === 'left' && <>{data.label} </>}
        <BlueCheckBox {...data} onChange={(e) => handleChange(e, data)} />
        {labelalign === 'right' && <span className="checkbox-label"> {data.label}</span>}
    </div>
    )
});

export default React.memo(BaseCheckBox);